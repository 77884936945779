import { useCallback, useEffect, useRef, useState } from 'react';
import cookie from 'react-cookies';
import { useSelector } from 'react-redux';
import { Flex } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { HomeButton, LastBreadcrumbChild } from 'Components/Breadcrumbs/Breadcrumbs';
import { TableSize } from 'Components/DataTable';
import Filters from 'Components/Filters';
import PageHeaderContainer from 'Components/PageHeaderContainer';
import { useUser } from 'Hooks/data/user/useUser';
import { useGa4Tracking } from 'Hooks/useConversionTracking';
import { PeriodFilterContainer } from 'Pages/Layout/ActionsMenu/components/PeriodFilterContainer';
import PausedDomainsTable from 'Pages/PausedDomains';
import { StoreType } from 'Types/Store';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import CardView from './CardView';
import DomainsActionBar from './DomainsActionBar';
import DomainsTable from './DomainsTable';
import { DisplayMode } from './PauseSwitch';

const useGa4TrackLogin = () => {
  const [ga4TrackLoginSent, setGa4TrackLoginSent] = useState(false);
  const user = useUser();

  useEffect(() => {
    if (ga4TrackLoginSent) return;
    if (!user.isAuthenticated) return;

    const storedUserId = cookie.load('ga4_login_user_id');
    if (storedUserId === user.id) {
      setGa4TrackLoginSent(true);
      return;
    }

    window.gtag('event', 'login', {
      userId: user.id,
    });
    if (user.id !== null && user.id !== undefined) {
      cookie.save('ga4_login_user_id', user.id, { path: '/' });
    }
    setGa4TrackLoginSent(true);
  }, [ga4TrackLoginSent, user.id, user.isAuthenticated]);
};

const DomainsPage = () => {
  useGa4Tracking();
  useGa4TrackLogin();
  const [display, setDisplay] = useState<DisplayMode>('domains');
  const cardViewRef = useRef<any>(null);

  const viewMode = useSelector(
    (store: StoreType) =>
      store.table?.[TableIDs.DOMAINS]?.viewMode ?? store.table?.viewMode ?? TableSize.DEFAULT,
  ) as TableSize;

  const isCardMode = viewMode === TableSize.CARD;

  const View = useCallback(() => {
    if (display === 'domains') {
      return isCardMode ? <CardView ref={cardViewRef} /> : <DomainsTable />;
    }
    return <PausedDomainsTable />;
  }, [display, isCardMode]);

  return (
    <>
      <Flex justify="space-between">
        <PageHeaderContainer breadcrumbs>
          <HomeButton />
          <IconChevronRight />
          <LastBreadcrumbChild>{t('Domains')}</LastBreadcrumbChild>
        </PageHeaderContainer>
        <PeriodFilterContainer />
      </Flex>
      {!isCardMode && <Filters disabled={display !== 'domains'} />}
      <DomainsActionBar
        viewMode={viewMode}
        display={display}
        setDisplay={setDisplay}
        handleSetGroup={cardViewRef.current?.handleSetGroup}
      />
      <View />
    </>
  );
};

export default DomainsPage;
