import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useFolderStructureQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import useUserPermission from 'Hooks/useUserPermission';
import EditMode from 'Pages/Keywords/Groupings/EditMode';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import { QueryOrder } from '../../../Constants';
import TableViewMode from './ViewMode/ViewMode';
import { useGroupingStore } from './support/groupingsStore';
import style from './groupings.module.scss';

function useOrderBy() {
  const [orderBy, setOrderBy] = useState('keywords');
  const [order, setOrder] = useState(QueryOrder.DESC);

  const handleSetOrderBy = useCallback(
    (orderKey: string, newOrder?: QueryOrder.ASC | QueryOrder.DESC) => {
      if (newOrder) {
        setOrder(newOrder);
        setOrderBy(orderKey);
      } else if (!newOrder && orderBy === orderKey) {
        setOrder(order === QueryOrder.ASC ? QueryOrder.DESC : QueryOrder.ASC);
      } else {
        setOrderBy(orderKey);
        setOrder(QueryOrder.DESC);
      }
    },
    [orderBy, order],
  );
  return { orderBy, order, setOrderBy: handleSetOrderBy };
}

export const Groupings = observer(() => {
  const [editMode, setEditMode] = useState(false);
  const [key, setKey] = useState(0);
  const { orderBy, order, setOrderBy } = useOrderBy();
  const incrementKey = () => setKey(key + 1);
  const { userHasWriteAccess } = useUserPermission();

  // We store nodes in ref, to avoid re-renders with state change
  const expandedNodesRef = useRef<string[]>([]);
  const expandedNodes = expandedNodesRef.current;
  const setExpandedNodes = (nodes) => {
    expandedNodesRef.current = Array.isArray(nodes) ? nodes : [nodes].filter(Boolean);
  };

  const groupingStore = useGroupingStore();

  const filters = useFilters();

  const {
    data: nestedData,
    loading: loadingFolders,
    refetch: refetchNested,
  } = useFolderStructureQuery({
    variables: { filters, nested: true, orderBy, order },
    onCompleted: (data) => {
      incrementKey();
      // If there is only one folder, expand it
      if (data?.folderStructure?.folderStructure?.length === 1) {
        setExpandedNodes(data?.folderStructure?.folderStructure[0]?.name);
      }
    },
  });

  const {
    data: flatData,
    loading: loadingEditMode,
    refetch: refetchFlat,
  } = useFolderStructureQuery({
    variables: { filters, nested: false, orderBy, order },
    onCompleted: () => {
      incrementKey();
    },
  });

  const refetch = () => {
    refetchNested();
    refetchFlat();
  };

  useEffect(() => {
    refetch();
  }, [groupingStore.refetchListener]);

  return (
    <div className={style.container}>
      {userHasWriteAccess && editMode ? (
        <EditMode
          initialExpanded={expandedNodes}
          setInitialExpanded={setExpandedNodes}
          setEditMode={setEditMode}
          flatData={flatData?.folderStructure?.folderStructure ?? []}
          loading={loadingEditMode}
          refetch={refetch}
          key={key}
        />
      ) : (
        <TableViewMode
          setEditMode={setEditMode}
          saveExpandedNodes={setExpandedNodes}
          data={nestedData?.folderStructure?.folderStructure as FolderType[]}
          loading={loadingFolders || loadingEditMode}
          initialExpanded={expandedNodes}
          orderBy={orderBy}
          order={order}
          setOrderBy={setOrderBy}
          key={key}
        />
      )}
    </div>
  );
});
