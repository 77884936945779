import { memo } from 'react';
import { Box } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import { useInView } from 'Hooks/intersectionObserver/useInView';
import { CollapseItem, ItemProps } from './CollapseItem';

const ItemContainer = (props: ItemProps) => {
  const { ref, inView } = useInView({
    rootMargin: '50px',
    triggerOnce: true,
  });
  return (
    <Box maw="full" ref={ref} mih="50px">
      {inView && <CollapseItem {...props} />}
    </Box>
  );
};

export default memo(ItemContainer, isEqual);
