import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Flex } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import { AccSelect } from 'Components/AccSelect';
import { LineChart } from 'Components/Chart/LineChart';
import { chartTooltipTypes } from 'Components/Chart/LineChart/support/types';
import AccText from 'Components/Text/AccText';
import { FolderMetric } from 'Ghql';
import { DefaultValue, useDisplayCurrencies, useTopCurrencies } from 'Hooks/displayCurrency';
import { useDeepMemo } from 'Hooks/useDeep';
import { t } from 'Utilities/i18n';
import { getMetricControlOptions } from '../support/getMetricControlOptions';
import { useFetchCharts } from '../support/useFetchCharts';
import { useComparisonContext } from '../useComparisonContext';
import styles from '../domainsComparison.module.scss';

const useChartHeight = () => {
  const { ref, height } = useElementSize();
  const chartHeight = useMemo(() => {
    if (height > 900) return 650;
    if (height > 700) return 550;
    if (height > 500) return 450;
    return 360;
  }, [height]);
  return { ref, height: chartHeight };
};

const CompareCharts = () => {
  const intl = useIntl();
  const [currency, setCurrency] = useState<string | null>(DefaultValue.organization);
  const { compareBy, setCompareBy, selected } = useComparisonContext();

  const isRank = compareBy === FolderMetric.AvgRank || compareBy === FolderMetric.AvgBaseRank;
  const isTrafficValue =
    compareBy === FolderMetric.TrafficValue || compareBy === FolderMetric.AiTrafficValue;
  const yAxisReversed = compareBy === FolderMetric.AvgPixelsFromTop;

  // use null for account default and if it is not traffic value
  const { series, displayCurrency, loading, error } = useFetchCharts(
    DefaultValue.organization === currency || !isTrafficValue ? null : currency,
  );
  const { ref, height } = useChartHeight();
  const topCurrencies = useTopCurrencies(true);

  const displayCurrencies = useDisplayCurrencies(
    topCurrencies,
    displayCurrency || t('Account Default'),
    DefaultValue.organization,
  );

  const seriesAreEmpty = !series?.length && !loading;
  const controlOptions = useMemo(getMetricControlOptions, []);
  const chartLabel = controlOptions.find((item) => item.value === compareBy)?.chartName;
  const fileName = `Chart ${chartLabel}`;

  const memoizedLineChart = useDeepMemo(() => {
    return (
      <LineChart
        key={compareBy + height + displayCurrency}
        isLoading={loading}
        series={series}
        isRank={isRank}
        multiLineNavigator
        fileName={fileName}
        enableRangeSelector
        disableLegend
        height={height}
        yAxisReversed={yAxisReversed}
        tooltip={{
          type: chartTooltipTypes.MULTI,
          customNumFormatter: (num: number) =>
            intl?.formatNumber(num, {
              style: displayCurrency ? 'currency' : 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
              currency: displayCurrency || undefined,
            }),
        }}
      />
    );
  }, [compareBy, height, loading, series, isRank, fileName, displayCurrency]);

  return (
    <Flex direction="column" p="xl" className={styles.modalCharts}>
      <div className={styles.segmentedControlWrapper}>
        <AccSegmentedControl data={controlOptions} value={compareBy} onChange={setCompareBy} />
      </div>
      {isTrafficValue && !loading && (
        <Flex direction="row" justify="center" align="center" mt={60} mb={-80} ml="auto" gap="xs">
          <AccText size="sm" fw={600}>
            {t('Currency:')}
          </AccText>
          <AccSelect
            options={displayCurrencies}
            value={currency}
            onChange={setCurrency}
            searchable
            groupHidden
            size="sm"
            inputMaxWidth={150}
          />
        </Flex>
      )}
      {!selected.length || seriesAreEmpty || error ? (
        <>
          <AccAlert
            mx="auto"
            w={600}
            my={110}
            severity="info"
            singleLine
            title={t('No data to compare')}
          >
            <AccText size="sm" fw={400}>
              {selected.length
                ? t(
                    'The selected items contains no comparable data. Select a different set of groups, domains, folders or tags to compare.',
                  )
                : t(
                    'Select one or more groups, domains, folders, or tags to view the comparison graph.',
                  )}
            </AccText>
          </AccAlert>
        </>
      ) : (
        <Box ref={ref} mt={80} flex="1">
          {memoizedLineChart}
        </Box>
      )}
    </Flex>
  );
};
export default CompareCharts;
