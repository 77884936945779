import compose from 'lodash/flowRight';
import { observer } from 'mobx-react';
import { AccContextToolbar } from 'Components/AccContextToolbar/AccContextToolbar';
import useUserPermission from 'Hooks/useUserPermission';
import queryDomainInfo from 'Pages/queryDomainInfo';
import { DomainInfo } from 'Query';
import { TableID } from 'Types/Table';
import BulkActions from './Actions/BulkActions';
import {
  WithKeywordActionMenuDataProps,
  withKeywordActionMenuData,
} from './Actions/withKeywordActionMenuData';

type Props = {
  tableName: TableID;
  domainInfo?: DomainInfo;
  isAllSelected: boolean;
  totalResults: number;
  manualSelectedKeywords: number[];
  showCompare: boolean;
  deselectAll: () => void;
};

const Toolbar = (props: Props & WithKeywordActionMenuDataProps) => {
  const { totalSelected, domainId, deselectAll, isAllSelected, domainInfo } = props;
  const { userHasWriteAccess } = useUserPermission();

  const { isDemoDomain } = domainInfo || {};
  const showActions = Boolean(
    (totalSelected > 0 || isAllSelected) && !isDemoDomain && userHasWriteAccess && domainId,
  );

  if (!showActions) return null;
  return (
    <AccContextToolbar
      enabled={!!totalSelected}
      onDisable={deselectAll}
      items={totalSelected}
      singularLabel={'keyword selected'}
      pluralLabel={'keywords selected'}
    >
      <BulkActions
        {...props}
        darkTheme
        showActions={showActions}
        withCount={false}
        withDivider={true}
      />
    </AccContextToolbar>
  );
};

export default compose(observer, withKeywordActionMenuData, queryDomainInfo())(Toolbar);
