import { useDispatch } from 'react-redux';
import { Box, Flex } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { deselectAllDomains } from 'Actions/DomainsPageActions';
import AccFeatureCallout from 'Components/AccButton/AccFeatureCallout';
import { TableSize } from 'Components/DataTable';
import { ViewMode } from 'Components/ViewMode';
import { useUser } from 'Hooks/data/user/useUser';
import { TableID } from 'Types/Table';
import { t, tct } from 'Utilities/i18n';

type Props = {
  tableName: TableID;
  isSimple: boolean;
};

const CardViewCalloutKey = 'HomeDashboardCardViewCallout';

const DomainsViewMode = ({ tableName, isSimple }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHideDialog] = useLocalStorage({
    key: CardViewCalloutKey,
    defaultValue: false,
  });
  const dispatch = useDispatch();
  const user = useUser();
  const isTrial = user.organization?.activePlan?.isTrial;
  const onChange = (tableSize: TableSize) => {
    if (tableSize === TableSize.CARD) {
      setHideDialog(true); // hide feature callout when user selects 'card' view mode
    }
    dispatch(deselectAllDomains());
  };

  return (
    <Flex direction="column">
      <ViewMode tableName={tableName} simple={isSimple} onChange={onChange} />
      {!isTrial && (
        <AccFeatureCallout
          popoverTitle={t('New Feature: Card View')}
          popoverText={tct(
            'The new [b:‘Card View’] is now available on your homepage dashboard. This feature offers a more visual and compact way to view your information. You can easily switch between the [b2:‘standard table’] and the [b3:‘new card’] view to match your preference. [br]Give it a try to enhance your dashboard experience!',
            {
              b: <strong />,
              b2: <strong />,
              b3: <strong />,
              br: <br />,
            },
          )}
          localStorageKey={CardViewCalloutKey}
          popoverTarget={<Box w={32} ml="auto" />}
        />
      )}
    </Flex>
  );
};
export default DomainsViewMode;
