import { Divider, Flex, FlexProps, Grid, Group, Space } from '@mantine/core';
import {
  IconChess,
  IconDeviceDesktopAnalytics,
  IconExternalLink,
  IconListSearch,
} from '@tabler/icons-react';
import AccAlert, { AccAlertProps } from 'Components/AccAlert/AccAlert';
import Text from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { t, tct } from 'Utilities/i18n';
import Link from '../components/Link';
import AdobeAnalyticsImage from '../images/AdobeAnalytics.png';
import CopyLookerStudioTemplateImage from '../images/CopyLookerStudioTemplate.png';
import DataboxImage from '../images/Databox.png';
import GoogleAnalyticsImage from '../images/GoogleAnalytics.png';
import GoogleLookerStudioImage from '../images/GoogleLookerStudio.png';
import GoogleSearchConsoleImage from '../images/GoogleSearchConsole.png';
import GoogleSheetsImage from '../images/GoogleSheets.png';
import RequestImage from '../images/Request.png';
import CSVImage from '../images/csv.png';
import ThirdPartyImage from '../images/third_party.png';
import type { Integration } from '../types/Integration';

const DEFAULT_CATEGORY = 'default';
const CUSTOM_CATEGORY = 'custom';

const Container = (props: FlexProps) => <Flex gap="sm" direction="column" {...props} />;
const Alert = (props: Omit<AccAlertProps, 'severity' | 'singleLine'>) => (
  <AccAlert severity="info" singleLine {...props} />
);

const getIntegrations = (category): Integration[] =>
  [
    {
      id: 'google-analytics',
      isPopular: false,
      category: DEFAULT_CATEGORY,
      image: GoogleAnalyticsImage,
      modalTitle: t('Google Analytics'),
      modalContent: (
        <Container>
          <Text>
            {t(
              'Google Analytics is the most popular digital analytics tool offered by Google that helps you to keep track of your customer’s journey by analysing visitor traffic.',
            )}
          </Text>
          <Text>
            {t(
              'Connecting AccuRanker to your Google Analytics account will provide more in-depth data such as Est. visitors per keyword and Landing pages/Tag cloud data for professional users.',
            )}
          </Text>
          <Text>
            {t(
              'Integration with Google Analytics is completely free of charge and our customer service team will be happy to help you if you have any questions.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct('Learn how to connect to Google Analytics with our [link:Step by step guide]', {
                link: (
                  <Link href="https://www.accuranker.com/help/integrations/connect-to-google-analytics" />
                ),
              })}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'adobe-analytics',
      category: CUSTOM_CATEGORY,
      image: AdobeAnalyticsImage,
      modalTitle: t('Adobe Analytics'),
      modalContent: (
        <Container>
          <Text>
            {t(
              'Adobe Analytics (formerly known as Omniture) is a widely popular analytics tool used for applying real-time analytics and detailed segmentation across all of your marketing channels. ',
            )}
          </Text>
          <Text>
            {t(
              'Connecting AccuRanker to your Adobe Analytics account will provide more in-depth data such as organic traffic, goals and where the traffic is coming from.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct('Learn how to connect to Adobe Analytics with our [link:Step by step guide]', {
                link: <Link href="https://www.accuranker.com/help/integrations/adobe-analytics" />,
              })}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'google-search-console',
      isPopular: false,
      category: DEFAULT_CATEGORY,
      image: GoogleSearchConsoleImage,
      modalTitle: t('Google Search Console'),
      modalContent: (
        <Container>
          <Text>
            {t(
              'Google Search Console (formerly known as Google Webmaster Tools) is a free web service by Google, that allows to check indexing status and optimize visibility of the website.',
            )}
          </Text>
          <Text>
            {t(
              'Connecting AccuRanker to your Google Search Console account will enable you to easily import your keywords. Integration with Google Search Console is completely free of charge and our customer service team will be happy to help you if you have any questions.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct(
                'Learn how to connect to Google Search Console with our [link:Step by step guide]',
                {
                  link: (
                    <Link href="https://www.accuranker.com/help/integrations/connect-to-google-search-console" />
                  ),
                },
              )}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'google-sheets',
      category: CUSTOM_CATEGORY,
      isPopular: false,
      image: GoogleSheetsImage,
      modalTitle: t('Google Sheets'),
      modalContent: (
        <Container>
          <Text>
            {t('Google Sheets is another integration that can help you streamline your reporting.')}
          </Text>
          <Text>
            {t(
              'Connecting Google Drive Account with AccuRanker will enable you to export your reports directly into Google Sheets.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct(
                'Learn how to export AccuRanker data into Google Spreadsheets with our [link:Step by step guide]',
                {
                  link: (
                    <Link href="https://www.accuranker.com/help/reports/google-spreadsheet-reports" />
                  ),
                },
              )}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'google-looker-studio',
      category: CUSTOM_CATEGORY,
      isPopular: false,
      image: GoogleLookerStudioImage,
      modalTitle: t('Google Looker Studio'),
      modalContent: (
        <Container>
          <Text>
            {t(
              'Google Looker Studio is a dashboard tool that turns your data into informative dashboards and reports that are easy to read and share.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct(
                'To use the AccuRanker Google Looker Studio data source use the following [link:link]',
                {
                  link: (
                    <Link href="https://datastudio.google.com/datasources/create?connectorId=AKfycbxJbixZDIRA9JPrzVIsQlJnNU9GE8hm2RWTu_bGOLHjk7QzaAtsX-7VTgZBrPPhchvc" />
                  ),
                },
              )}
            </Text>
          </Alert>
          <Text>
            {t(
              'Connecting Google Looker Studio with AccuRanker will enable you to show advanced AccuRanker data on Google Looker Studio dashboard in a simple and sleek way.',
            )}
          </Text>
          <Text>
            {t(
              'Integration with Google Looker Studio is completely free of charge and our customer service team will be happy to help you if you have any questions.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct(
                'Learn how to export AccuRanker data into Google Looker Studio with our [link:Step by step guide]',
                {
                  link: (
                    <Link href="https://www.accuranker.com/help/integrations/connecting-to-google-data-studio" />
                  ),
                },
              )}
            </Text>
          </Alert>
          <Space h="xxs" />
          <Divider />
          <Space h="xxs" />
          <AccTitle type="h3">{t('Pre-made templates')}</AccTitle>
          <Text>
            {t(
              'AccuRanker has created three templates to help you get started presenting your data in Google Looker Studio:',
            )}
          </Text>
          <Grid columns={2}>
            <Grid.Col span={1}>
              <Group ml={8} gap={4} mb="sm" c="gray.7">
                <IconDeviceDesktopAnalytics size={24} color="currentColor" />
                <Link
                  href="https://lookerstudio.google.com/reporting/8aa327e2-cb76-4640-99ab-a58cc58da740"
                  target="_blank"
                >
                  {t('Dashboard')} <IconExternalLink size={16} />
                </Link>
              </Group>
              <Group ml={8} gap={4} mb="sm" c="gray.7">
                <IconListSearch size={24} color="currentColor" />
                <Link
                  href="https://lookerstudio.google.com/reporting/fed08f3e-926f-48be-95ae-7e8df9ff2f72"
                  target="_blank"
                >
                  {t('Keywords')} <IconExternalLink size={16} />
                </Link>
              </Group>
              <Group ml={8} gap={4} c="gray.7">
                <IconChess size={24} color="currentColor" />
                <Link
                  href="https://lookerstudio.google.com/reporting/3bb07fee-954c-45d2-b1c4-4129aeb391e4"
                  target="_blank"
                >
                  {t('Competitors')} <IconExternalLink size={16} />
                </Link>
              </Group>
              <Space h="md" />
              <Text>
                {tct(
                  'Once you\'ve connected AccuRanker and Looker Studio as explained above, you can copy a template by selecting [b: Make a copy] from the [b2: More options] menu in the upper right corner.',
                  { b: <b />, b2: <b /> },
                )}
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Group align="center" justify="center">
                <img
                  src={CopyLookerStudioTemplateImage}
                  width="200px"
                  height="200px"
                  style={{ borderRadius: '4px' }}
                  alt={t(
                    'screenshot showing the \'Make a copy\' option inside the \'More options\' menu in Looker Studio',
                  )}
                />
              </Group>
            </Grid.Col>
          </Grid>
        </Container>
      ),
    },
    {
      id: 'csv',
      category: DEFAULT_CATEGORY,
      image: CSVImage,
      modalTitle: t('CSV Importer'),
      modalContent: (
        <Container>
          <Text>{t('With our bulk importer you are able to import data from a CSV file.')}</Text>
          <Alert>
            <Text>
              {tct(
                'To use the AccuRanker CSV importer go to the the importer and [link:Upload CSV]',
                {
                  link: <Link target="_self" href="/import" />,
                },
              )}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'third-party',
      category: DEFAULT_CATEGORY,
      image: ThirdPartyImage,
      modalTitle: t('Third-party Importer'),
      modalContent: (
        <Container>
          <Text>
            {t(
              'With our third-party importers we can import your data from a range of other rank tracking tools. Contact our support and we will be happy to help you import your data into AccuRanker from a third-party tool.',
            )}
          </Text>
        </Container>
      ),
    },
    {
      id: 'databox',
      category: CUSTOM_CATEGORY,
      image: DataboxImage,
      modalTitle: t('Databox'),
      modalContent: (
        <Container>
          <Text>
            {t(
              'Databox is a decision-making platform built to help you track performance, discover insights and understand what\'s going on with your business. It connects your cloud services, spreadsheets, databases and custom integrations to organize all of your business KPIs in one place. Databox will deliver your metrics via mobile, browser, big screen, Apple Watch®, and even Slack.',
            )}
          </Text>
          <Text>
            {t(
              'View analytics data from AccuRanker, HubSpot, Google Analytics, SEMRush, Wistia, Drift, Youtube, Facebook Advertising, Instagram, X and many more sources in just a few clicks. You can easily pull all of your KPIs into one place, build your own dashboards with no coding required, set measurable goals to track your progress and get performance alerts, recommendations or important KPIs delivered to you when they matter most. This integration provides robust reporting capabilities outside of the AccuRanker reporting app. If AccuRanker provides an API for the data, we make it possible for you to visualize it in Databox. Used by thousands of AccuRanker customers in total, Databox is focused on serving the needs of AccuRanker customers.',
            )}
          </Text>
          <Alert>
            <Text>
              {tct('You can read more and enable the integration [link:here]', {
                link: (
                  <Link href="https://databox.com/?utm_source=AccuRanker&utm_medium=Marketplace&utm_campaign=Integration%20marketplace" />
                ),
              })}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'api',
      category: CUSTOM_CATEGORY,
      image: ThirdPartyImage,
      modalTitle: t('API'),
      modalContent: (
        <Container>
          <Text>
            {t('Using this API you will be able to retrieve metrics from your AccuRanker account.')}
          </Text>
          <Alert>
            <Text>
              {tct('To learn more about the API please refer to the [link:API documentation]', {
                link: <Link target="_self" href="/api" />,
              })}
            </Text>
          </Alert>
        </Container>
      ),
    },
    {
      id: 'request-info',
      category: CUSTOM_CATEGORY,
      image: RequestImage,
      modalTitle: t('Request Integration'),
      modalContent: (
        <Container>
          <Alert>
            {t(
              'If you wish to request an integration, please send an email to support@accuranker.com',
            )}
          </Alert>
        </Container>
      ),
    },
  ].filter((item) => item.category === category);

export const useIntegrations = () => {
  return {
    defaultIntegrations: getIntegrations(DEFAULT_CATEGORY),
    customIntegrations: getIntegrations(CUSTOM_CATEGORY),
  };
};
