import React, { useMemo } from 'react';
import { Box, Center, Flex, Group } from '@mantine/core';
import { IconFold, IconFoldDown } from '@tabler/icons-react';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import FormatNumber, { FormatNumberAuto } from 'Components/FormatNumber/new';
import { useTableSetting } from 'Components/Modal/Content/TableSettingsModal/support/config';
import { useStateColumns } from 'Components/Modal/Content/TableSettingsModal/support/hooks/useStateColumns';
import { withScrollOptimize } from 'Components/Table/VirtualizedTable';
import { FolderMetric } from 'Ghql';
import { useIsPercentage, useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import { useDisplayCurrency } from 'Hooks/displayCurrency';
import { useFoldersGraph } from 'Pages/Keywords/Groupings/HistoryGraph/foldersGraph';
import { FolderType, FolderTypeWithTreeInfo } from 'Pages/Keywords/Groupings/groupingTypes';
import {
  FolderColumnID,
  NaverFolderColumnBlacklist,
} from 'Pages/Keywords/Groupings/support/constants';
import { TreeTableHeaderColumnWithSearch } from 'Pages/SiteMapping/components/TreeTable/Header';
import { DomainTypeChoices } from 'Query';
import { TableIDs } from 'Types/Table';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import AboveTheFoldIcon from 'icons/abovethefold.svg';
import HeaderColumn from '../components/HeaderColumn';
import CompetitorPositionCell from '../components/cells/CompetitorPositionCell';
import { DeviceCell } from '../components/cells/DeviceCell';
import { DiscoveredKeywordsCell } from '../components/cells/DiscoveredKeywordsCell';
import { FolderDescriptionCell } from '../components/cells/FolderDescriptionCell';
import FoldersMaxCtrCell from '../components/cells/FoldersMaxCtrCell';
import KeywordsCell from '../components/cells/KeywordsCell';
import PathCell from '../components/cells/PathCell';
import RankingDistributionCell from '../components/cells/RankingDistributionCell';
import SearchIntentCell from '../components/cells/SearchIntentCell';
import ValueAndIndicatorCell from '../components/cells/ValueAndIndicatorCell';
import WinnersOrLosersCell from '../components/cells/WinnersOrLosersCell';

export type FolderCellProps = {
  classes: Record<string, string>;
  rootNode: FolderTypeWithTreeInfo;
  isOpen: boolean;
  isHovered?: boolean;
  isSelected?: boolean;
  onToggle: any;
  className?: string;
  hideConnectors?: string[];
  expandDataKey: string;
  updateSelectedNode: (
    node: FolderType,
    event: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent,
  ) => void;
  onDragEnd?: (event: any, text?) => void;
};

// eslint-disable-next-line import/no-unused-modules
export type TableColumnConfig = {
  id: string;
  title?: string;
  orderBy?: string;
  orderByChange?: string;
  width?: string;
  withChange?: boolean;
  withCurrency?: boolean;
  cellRenderer: (props: any) => React.ReactNode;
  sticky?: boolean;
};

function useFolderTableSettings() {
  const { selector, columns } = useTableSetting(TableIDs.FOLDERS);
  const tableSettings = useStateColumns(selector);

  return useMemo(() => {
    if (isNil(tableSettings)) {
      return columns
        ?.filter((column) => !column.tableSetting?.defaultHide)
        ?.map((column) => column.id) as string[];
    }
    return tableSettings;
  }, [tableSettings]);
}

const CellModalLink = ({
  rootNode,
  className,
  defaultTab,
  children,
  disabled,
  tooltip,
}: {
  rootNode: any;
  className?: string;
  defaultTab: string;
  children: JSX.Element;
  disabled?: boolean;
  tooltip?: string;
}) => {
  const showGraph = useFoldersGraph(() => [rootNode], defaultTab);
  return (
    <AccTooltip tooltip={tooltip ? tooltip : t('Show history')}>
      <Flex
        ml="auto"
        className={cn(className, !disabled ? 'table-link-like' : '')}
        key={`${rootNode.id}-modal-link-${defaultTab}`}
        onClick={
          !disabled
            ? (event) => {
                event.stopPropagation();
                showGraph();
              }
            : undefined
        }
      >
        {children}
      </Flex>
    </AccTooltip>
  );
};

const useFoldersTableColumnsConfig = (): TableColumnConfig[] => {
  const tableSettings = useFolderTableSettings();
  const { domainInfo } = useQueryDomainInfo();
  const isPercentage = useIsPercentage();
  const isNaver = domainInfo?.domainType === DomainTypeChoices.A_7;
  const isDemoDomain = !!domainInfo?.isDemoDomain;
  const tableConfig = useMemo(
    () => [
      {
        id: FolderColumnID.CHECKBOX,
        width: '45px',
        cellRenderer: () => null,
        sticky: true,
      },
      {
        id: FolderColumnID.FOLDER,
        title: t('Folder'),
        orderBy: 'folder',
        width: 'minmax(300px, 4fr)',
        cellRenderer: (props: FolderCellProps) => (
          <PathCell key={FolderColumnID.FOLDER} {...props} isDemoDomain={isDemoDomain} />
        ),
        sticky: true,
      },
      {
        id: FolderColumnID.DESCRIPTION,
        title: t('Description'),
        width: 'minmax(150px, 1fr)',
        cellRenderer: (props: FolderCellProps) => {
          return <FolderDescriptionCell {...props} key={FolderColumnID.DESCRIPTION} />;
        },
      },
      {
        id: FolderColumnID.DEVICE,
        title: t('Device'),
        orderBy: 'mobile_percentage',
        width: 'minmax(130px, 1fr)',
        cellRenderer: (props: FolderCellProps) => {
          return <DeviceCell {...props} key={FolderColumnID.DEVICE} />;
        },
      },
      {
        id: FolderColumnID.KEYWORDS,
        title: t('Keywords'),
        orderBy: 'keywords',
        width: 'minmax(110px, 0.7fr)',
        cellRenderer: (props: FolderCellProps) => {
          return <KeywordsCell {...props} key={FolderColumnID.KEYWORDS} />;
        },
      },
      {
        id: FolderColumnID.DISCOVERED_KEYWORDS,
        title: t('Disc. keywords'),
        width: 'minmax(125px, 0.8fr)',
        cellRenderer: (props: FolderCellProps) => {
          return <DiscoveredKeywordsCell props={props} key={FolderColumnID.DISCOVERED_KEYWORDS} />;
        },
      },
      {
        id: FolderColumnID.DYNAMIC_COMPETITOR_POSITION,
        title: t('Market position'),
        orderBy: 'dynamic_competitor_position',
        width: 'minmax(165px, 1fr)',
        cellRenderer: (props: FolderCellProps) => (
          <CompetitorPositionCell {...props} key={FolderColumnID.DYNAMIC_COMPETITOR_POSITION} />
        ),
      },
      {
        id: FolderColumnID.ORGANIC_TRAFFIC,
        title: t('AI SoV'),
        orderBy: 'ai_share_of_voice',
        orderByChange: 'ai_share_of_voice_change',
        width: 'minmax(145px, 0.7fr)',
        cellRenderer: (props: FolderCellProps) => {
          return (
            <ValueAndIndicatorCell
              className={props.classes.rightTextAlign}
              key={FolderColumnID.ORGANIC_TRAFFIC}
              defaultTab={FolderMetric.EstimatedVisits}
              rootNode={props.rootNode}
              disabled={isNil(props.rootNode.ai_share_of_voice)}
              beforeValue={props.rootNode.ai_share_of_voice_compare}
              value={props.rootNode.ai_share_of_voice}
              maxValue={props.rootNode.max_ai_share_of_voice}
            >
              <AccTooltip tooltip={t('Show history for AI Share of Voice')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.ai_share_of_voice}
                    maximumFractionDigits={1}
                    minimumFractionDigits={0}
                  />
                </span>
              </AccTooltip>
            </ValueAndIndicatorCell>
          );
        },
      },
      {
        id: FolderColumnID.SHARE_OF_VOICE,
        title: t('SoV'),
        orderBy: 'share_of_voice_value',
        orderByChange: 'share_of_voice_change',
        width: 'minmax(145px, 0.8fr)',
        cellRenderer: (props: FolderCellProps) => (
          <ValueAndIndicatorCell
            className={props.classes.rightTextAlign}
            key={FolderColumnID.SHARE_OF_VOICE}
            defaultTab={FolderMetric.ShareOfVoice}
            rootNode={props.rootNode}
            disabled={isNil(props.rootNode.share_of_voice_value)}
            beforeValue={props.rootNode.share_of_voice_value_compare}
            value={props.rootNode.share_of_voice_value}
            maxValue={props.rootNode.max_share_of_voice}
            indicatorStyle={isPercentage ? 'percent' : 'decimal'}
          >
            <AccTooltip tooltip={t('Show history for Share of Voice')}>
              <span>
                <FormatNumberAuto
                  value={props.rootNode.share_of_voice_value}
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.AVG_RANK_VALUE,
        title: t('Avg. rank'),
        orderBy: 'avg_rank',
        orderByChange: 'avg_rank_change',
        width: 'minmax(115px, 0.7fr)',
        cellRenderer: (props: FolderCellProps) => (
          <ValueAndIndicatorCell
            key={FolderColumnID.AVG_RANK_VALUE}
            defaultTab={FolderMetric.AvgRank}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            disabled={!props.rootNode.avg_rank}
            beforeValue={props.rootNode.avg_rank_compare || null}
            value={props.rootNode.avg_rank}
            negate={true}
            maxDecimals={1}
          >
            {props.rootNode.avg_rank !== 0 ? (
              <AccTooltip tooltip={t('Show history for average rank')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.avg_rank}
                    minimumFractionDigits={1}
                    maximumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            ) : (
              t('Not in top 100')
            )}
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.AVG_BASE_RANK,
        title: t('Avg. base rank'),
        orderBy: 'avg_base_rank',
        orderByChange: 'avg_base_rank_change',
        width: 'minmax(115px, 0.7fr)',
        cellRenderer: (props: FolderCellProps) => (
          <ValueAndIndicatorCell
            key={FolderColumnID.AVG_BASE_RANK}
            defaultTab={FolderMetric.AvgBaseRank}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            disabled={!props.rootNode.avg_base_rank}
            beforeValue={props.rootNode.avg_base_rank_compare || null}
            value={props.rootNode.avg_base_rank}
            negate={true}
            maxDecimals={1}
          >
            {props.rootNode.avg_base_rank !== 0 ? (
              <AccTooltip tooltip={t('Show history for average base rank')}>
                <span>
                  <FormatNumber
                    value={props.rootNode.avg_base_rank}
                    minimumFractionDigits={1}
                    maximumFractionDigits={1}
                  />
                </span>
              </AccTooltip>
            ) : (
              t('Not in top 100')
            )}
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.SEARCH_VOLUME,
        title: t('Searches'),
        orderBy: 'search_volume_value',
        width: 'minmax(105px, 1fr)',
        cellRenderer: (props: FolderCellProps) => {
          return (
            <CellModalLink
              className={props.classes.rightTextAlign}
              key={FolderColumnID.SEARCH_VOLUME}
              defaultTab={FolderMetric.SearchVolume}
              rootNode={props.rootNode}
              disabled={isNil(props.rootNode.search_volume_value)}
              tooltip={t('Show history for search volume')}
            >
              <FormatNumber
                value={props.rootNode.search_volume_value}
                maximumFractionDigits={1}
                minimumFractionDigits={0}
              />
            </CellModalLink>
          );
        },
      },

      {
        id: FolderColumnID.AI_TRAFFIC_VALUE,
        title: t('AI Traf. val'),
        orderBy: 'ai_traffic_value',
        orderByChange: 'ai_traffic_value_change',
        withCurrency: true,
        width: 'minmax(150px, 0.8fr)',
        cellRenderer: (props: FolderCellProps) => (
          <ValueAndIndicatorCell
            defaultTab={FolderMetric.AiTrafficValue}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={FolderColumnID.AI_TRAFFIC_VALUE}
            disabled={!props.rootNode.ai_traffic_value}
            beforeValue={props.rootNode.ai_traffic_value_compare}
            value={props.rootNode.ai_traffic_value}
            maxValue={props.rootNode.max_ai_traffic_value}
          >
            <AccTooltip tooltip={t('Show history for AI Traffic value')}>
              <span>
                <FormatNumber
                  value={props.rootNode.ai_traffic_value}
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },

      {
        id: FolderColumnID.COST_VALUE,
        title: t('Traf. val'),
        orderBy: 'traffic_value',
        orderByChange: 'traffic_value_change',
        withCurrency: true,
        width: 'minmax(150px, 0.8fr)',
        cellRenderer: (props: FolderCellProps) => (
          <ValueAndIndicatorCell
            defaultTab={FolderMetric.TrafficValue}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={FolderColumnID.COST_VALUE}
            disabled={!props.rootNode.traffic_value}
            beforeValue={props.rootNode.traffic_value_compare}
            value={props.rootNode.traffic_value}
            maxValue={props.rootNode.max_traffic_value}
          >
            <FormatNumber
              value={props.rootNode.traffic_value}
              maximumFractionDigits={1}
              minimumFractionDigits={1}
            />
          </ValueAndIndicatorCell>
        ),
      },

      {
        id: FolderColumnID.DYNAMIC_CTR,
        title: t('Avg. CTR'),
        orderBy: 'dynamic_ctr_value',
        orderByChange: 'traffic_value_change',
        width: 'minmax(145px, 0.7fr)',
        cellRenderer: (props: FolderCellProps) => (
          <ValueAndIndicatorCell
            className={props.classes.rightTextAlign}
            defaultTab={FolderMetric.AvgCtr}
            key={FolderColumnID.DYNAMIC_CTR}
            rootNode={props.rootNode}
            disabled={isNil(props.rootNode.dynamic_ctr_value)}
            beforeValue={props.rootNode.dynamic_ctr_compare}
            value={props.rootNode.dynamic_ctr_value}
            indicatorStyle="percent"
            maxValue={props.rootNode.dynamic_ctr_max_value}
          >
            <AccTooltip tooltip={t('Show history for average CTR')}>
              <span>
                <FormatNumber
                  value={props.rootNode.dynamic_ctr_value}
                  style="percent"
                  maximumFractionDigits={1}
                  minimumFractionDigits={1}
                />
              </span>
            </AccTooltip>
          </ValueAndIndicatorCell>
        ),
      },
      {
        id: FolderColumnID.DYNAMIC_CTR_MAX,
        title: t('Avg. Max CTR'),
        orderBy: 'dynamic_ctr_max_value',
        width: 'minmax(120px, 0.7fr)',
        cellRenderer: (props: FolderCellProps) => (
          <FoldersMaxCtrCell
            key={FolderColumnID.DYNAMIC_CTR_MAX}
            maxCtr={props.rootNode.dynamic_ctr_max_value}
            path={props.rootNode.path}
          />
        ),
      },
      {
        id: FolderColumnID.INTENT,
        title: t('Intent'),
        width: 'minmax(105px, 1fr)',
        cellRenderer: (props: FolderCellProps) => (
          <SearchIntentCell {...props} key={FolderColumnID.INTENT} />
        ),
      },

      {
        id: FolderColumnID.WINNERS,
        title: t('Winners'),
        orderBy: 'winners',
        width: 'minmax(90px, 1fr)',
        cellRenderer: (props: FolderCellProps) => {
          return (
            <WinnersOrLosersCell props={props} cellType={'winners'} key={FolderColumnID.WINNERS} />
          );
        },
      },
      {
        id: FolderColumnID.LOSERS,
        title: t('Losers'),
        orderBy: 'losers',
        width: 'minmax(90px, 1fr)',
        cellRenderer: (props: FolderCellProps) => {
          return (
            <WinnersOrLosersCell props={props} cellType={'losers'} key={FolderColumnID.LOSERS} />
          );
        },
      },

      {
        id: FolderColumnID.RANKING_DISTRIBUTION,
        title: t('Rank dist.'),
        width: 'minmax(120px, 0.5fr)',
        cellRenderer: (props: FolderCellProps) => {
          return <RankingDistributionCell key={FolderColumnID.RANKING_DISTRIBUTION} {...props} />;
        },
      },
      {
        id: FolderColumnID.ABOVE_THE_FOLD,
        title: t('Above the Fold'),
        orderBy: 'above_the_fold',
        width: 'minmax(125px, 0.8fr)',
        cellRenderer: (props: FolderCellProps) => (
          <CellModalLink
            defaultTab={FolderMetric.AboveTheFold}
            rootNode={props.rootNode}
            className={props.classes.rightTextAlign}
            key={FolderColumnID.ABOVE_THE_FOLD}
            disabled={!props.rootNode.above_the_fold}
            tooltip={t('Show history for Above the fold')}
          >
            <Group gap="xxs">
              <FormatNumber value={props.rootNode.above_the_fold} />
              <AccFastIcon
                fit="contain"
                src={AboveTheFoldIcon}
                color="#f89537"
                tooltip={t('Above the fold')}
                size={16}
              />
            </Group>
          </CellModalLink>
        ),
      },
    ],
    [],
  );
  return useMemo(() => {
    return tableConfig
      .filter((column) => (tableSettings as string[])?.includes(column.id))
      .filter((column) => !(isNaver && NaverFolderColumnBlacklist.includes(column.id)))
      .map((e, index) => {
        // 1 priority cell, set number of cells that has high priority or Infinity to re-render full row
        if (index > 1) {
          return {
            ...e,
            cellRenderer: withScrollOptimize(e.cellRenderer),
          };
        }
        return e;
      });
  }, [tableSettings]);
};

const RowTemplate = (props) => {
  const columnConfigs = useFoldersTableColumnsConfig();

  return columnConfigs.map((config) => {
    const CellRenderer: any = config.cellRenderer ?? (() => null);
    return <CellRenderer {...props} key={config.id} />;
  });
};

const Header = React.memo(
  ({
    submitSearch,
    className,
    activeSearch,
    setOrderBy,
    order,
    orderBy,
    handleExpandOrCollapseAllNodes,
    expandNodes,
  }: any) => {
    const { displayCurrency } = useDisplayCurrency();
    const columnConfigs = useFoldersTableColumnsConfig();
    const gridTableRows = `${columnConfigs.map((c) => c.width).join(' ')}`;
    return (
      <Box className={className} style={{ display: 'grid', gridTemplateColumns: gridTableRows }}>
        {handleExpandOrCollapseAllNodes ? (
          <Center style={{ position: 'sticky', left: 0, zIndex: 3 }}>
            <AccTooltip tooltip={expandNodes ? t('Expand all') : t('Collapse all')}>
              <AccActionIcon onClick={handleExpandOrCollapseAllNodes}>
                {expandNodes ? <IconFoldDown size={18} /> : <IconFold size={18} />}
              </AccActionIcon>
            </AccTooltip>
          </Center>
        ) : (
          <div />
        )}
        <TreeTableHeaderColumnWithSearch
          name={t('Folder')}
          submitSearch={submitSearch}
          activeSearch={activeSearch}
          currentOrder={order}
          currentOrderBy={orderBy}
          orderBy={'folder'}
          setOrderBy={setOrderBy}
          sticky
        />
        {columnConfigs.slice(2, columnConfigs.length + 1).map((header) => (
          <HeaderColumn
            key={`${header.title}-${header.id}`}
            header={header}
            content={`${header.title} ${
              header.withCurrency && displayCurrency ? `(${displayCurrency})` : ''
            }`}
            setOrderBy={setOrderBy}
            order={order}
            orderBy={orderBy}
          />
        ))}
      </Box>
    );
  },
  propsIsEqualComparison,
);
Header.displayName = 'Header';

export function useFoldersTableConfig() {
  const headerConfig = useFoldersTableColumnsConfig();

  const gridTableRows = `${headerConfig.map((c) => c.width).join(' ')}`;

  return {
    Header,
    RowTemplate,
    gridTableRows,
  };
}
