/* eslint-disable react/display-name */
import { useFormState } from 'react-final-form';
import AccButton from 'Components/AccButton/AccButton';
import SimpleModal from 'Components/SimpleModal';
import AccText from 'Components/Text/AccText';
import { KeywordOveruseCode } from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { t, tn } from 'Utilities/i18n';
import { useKeywordCountInfo, useKeywordRawCount } from '../hooks';
import { AddKeywordsModalType, FormState } from '../types';
import { formatAddKeywordCountDescription } from '../utils';

export default ({
  mode,
  responseCode,
  keydisKeywordsCounter,
}: {
  mode: AddKeywordsModalType;
  responseCode: KeywordOveruseCode | null;
  keydisKeywordsCounter?: number;
}) => {
  const keywordsRawCount = useKeywordRawCount(mode, keydisKeywordsCounter);
  const { keywordsCounter, keywordCountInfo } = useKeywordCountInfo(keywordsRawCount);

  const { submitting } = useFormState<FormState>();

  const { hideModal } = useModal();

  const description = formatAddKeywordCountDescription(
    keywordsCounter,
    keywordsRawCount,
    keywordCountInfo,
  );

  return (
    <SimpleModal.Footer>
      <AccText variant="label">
        <div className="keywords-count">
          {t('Keywords to be added:')}
          <b>&nbsp;{keywordsCounter}&nbsp;</b>
          {description ? <small>{`= ${description}`}</small> : null}
        </div>
      </AccText>

      <AccButton variant="tertiary" onClick={hideModal}>
        {t('Cancel')}
      </AccButton>

      <AccButton
        type="submit"
        disabled={keywordsCounter === 0 || submitting || responseCode !== null}
      >
        {tn('Add keyword', 'Add keywords ', keywordsCounter)}
      </AccButton>
    </SimpleModal.Footer>
  );
};
