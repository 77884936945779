import { cloneElement } from 'react';
import { ActionIcon, Flex, FloatingPosition, Popover } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconBulb, IconX } from '@tabler/icons-react';
import AccText from 'Components/Text/AccText';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import AccButton from './AccButton';
import { useOpenWithDelay } from './support/hooks';
import styles from './AccFeatureCallout.module.scss';

type Props = {
  popoverTitle: string;
  popoverText: string;
  popoverTarget: JSX.Element;
  popOverDelay?: number;
  localStorageKey: string;
  learnMoreHref?: string;
  popoverPosition?: FloatingPosition;
};

const DEFAULT_OPEN_POPUP_DELAY = 2000;

const AccFeatureCallout = (props: Props) => {
  const {
    popoverText,
    popOverDelay,
    localStorageKey,
    popoverTarget,
    popoverTitle,
    learnMoreHref,
    popoverPosition = 'top-end',
  } = props;

  const trackEvent = useMixpanel();

  const trackClick = (eventName: string) => {
    const extraProps = {
      Text: eventName,
    };
    trackEvent(EventName.FeatureCallout, extraProps);
  };

  const [hideDialog, setHideDialog] = useLocalStorage({
    key: localStorageKey,
    defaultValue: false,
    getInitialValueInEffect: true,
  });
  const opened = useOpenWithDelay(hideDialog, popOverDelay || DEFAULT_OPEN_POPUP_DELAY);

  const extendedPopoverTarget = cloneElement(popoverTarget as any, {
    onClick: () => {
      setHideDialog(true);
      popoverTarget.props?.onClick?.();
    },
  });

  return (
    <Popover
      withArrow
      arrowSize={12}
      position={popoverPosition}
      opened={opened}
      disabled={hideDialog}
      shadow="xs"
      transitionProps={{ duration: 300, transition: 'pop-bottom-right' }}
      width={320}
    >
      <Popover.Target>{extendedPopoverTarget}</Popover.Target>
      <Popover.Dropdown>
        <Flex align={'center'} gap={8} c="gray.8">
          <IconBulb size={24} />
          <AccText size="16px" variant="label" fw={700}>
            {popoverTitle}
          </AccText>
          <ActionIcon
            ml={'auto'}
            variant="subtle"
            color="gray"
            onClick={() => {
              setHideDialog(true);
              trackClick('CloseFeatureCallout');
            }}
          >
            <IconX size={16} color={'gray'} />
          </ActionIcon>
        </Flex>
        <AccText mt={'xs'} className={styles.popoverText}>
          {popoverText}
        </AccText>
        <Flex direction="row" align="center" mt={'sm'}>
          {learnMoreHref && (
            <AccText
              variant="link"
              target="_blank"
              href={learnMoreHref}
              onClick={() => {
                trackClick('LearnMoreFeatureCallout');
              }}
            >
              {t('Click here to learn more')}
            </AccText>
          )}
          <AccButton
            ml={'auto'}
            variant="secondary"
            onClick={() => {
              setHideDialog(true);
              trackClick('CloseFeatureCallout');
            }}
          >
            {t('Got it!')}
          </AccButton>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
AccFeatureCallout.displayName = 'AccPopoverButton';

// eslint-disable-next-line import/no-unused-modules
export default AccFeatureCallout;
