import cookie from 'react-cookies';
import * as Sentry from '@sentry/react';
import config from 'config';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import {
  ErrorType,
  PricingPlanNode,
  RegisterCompleteWizardInput,
  RegisterPageUnconfirmedUserQuery,
  UsersUnconfirmedUserSignupTypeChoices,
  useRegisterPageUserRegisterMutation,
  useUpdateWizardAccountInfoMutation,
} from 'Ghql';
import { extractQueryFormErrors } from 'Hooks/Graphql/helpers';
import { useModal } from 'Hooks/base/useModal';
import { RouteMatch } from 'Utilities/Router/hooks/useRouteMatch';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import Validator from 'Utilities/validation';
import { FIELD_NAMES, PAY, TRIAL_CC_AND_PAY } from '..';
import { EventName, useMixpanel } from '../../../Utilities/Analytics/mixpanel';

type Props = {
  signupType?: UsersUnconfirmedUserSignupTypeChoices;
  braintreeInstance?: any;
  setBraintreeUniqueId: (id: number) => void;
  match: RouteMatch;
  pricingPlan?: PricingPlanNode;
  unconfirmedUserData?: RegisterPageUnconfirmedUserQuery;
};

export const useRegistrationSubmit = (props: Props) => {
  const {
    signupType,
    braintreeInstance,
    setBraintreeUniqueId,
    match,
    pricingPlan,
    unconfirmedUserData,
  } = props;

  const [registerWithPaymentMutation] = useRegisterPageUserRegisterMutation();
  const [registerMutation] = useUpdateWizardAccountInfoMutation();

  const { showModal } = useModal();
  const trackEvent = useMixpanel();

  const showRegisterFailedModal = (errors: ErrorType[]) => {
    trackEvent(EventName.RegisterFailed, { errors: JSON.stringify(errors) });
    showModal({
      modalType: 'RegisterFailed',
      modalProps: {
        errors,
      },
    });
  };

  const handleRegisterWithPayment = async (values, nonce) => {
    const queryParams = new URLSearchParams(location.search);
    const refCode = queryParams.get('ref_code') || cookie.load('ref_code') || '';
    const campaignId = queryParams.get('ar_campaign') || cookie.load('ar_campaign') || '';
    const referrer = // Check for the correct spelling of referrer and for the http spec spelling (referer)
      queryParams.get('ar_referrer') ||
      queryParams.get('ar_referer') ||
      cookie.load('ar_referrer') ||
      cookie.load('ar_referer') ||
      '';

    const companyInfoCountryIso = values?.[FIELD_NAMES.COUNTRY];
    if (!companyInfoCountryIso) {
      Sentry.captureException(new Error('Country not found on register form'));
      return showRegisterFailedModal([
        { field: 'all', messages: [t('Registration failed. Please contact support.')] },
      ]);
    }

    const input = {
      unconfirmedUserId: match?.params?.id || '',
      planId: pricingPlan?.id || '',
      billingCycle: '1',
      fullName: values?.[FIELD_NAMES.FULL_NAME],
      password: values?.[FIELD_NAMES.PASSWORD],
      organizationName: values?.[FIELD_NAMES.ORG_URL],
      organizationUrl: values?.[FIELD_NAMES.ORG_URL],
      targetDomain: values?.[FIELD_NAMES.ORG_URL],
      campaignId,
      refCode,
      referrer,
      captcha: 'this-is-not-a-hack',
      paymentNonce: nonce,
      companyInfoStreet: values?.[FIELD_NAMES.STREET],
      companyInfoZipcode: values?.[FIELD_NAMES.ZIPCODE],
      companyInfoCity: values?.[FIELD_NAMES.CITY],
      companyInfoState: values?.[FIELD_NAMES.STATE],
      companyInfoCountryIso,
      companyInfoVatPrefix: values?.[FIELD_NAMES.VAT_PREFIX] ?? null,
      companyInfoVatNumber: values?.[FIELD_NAMES.VAR_NUMBER] ?? null,
    };

    const { data: registerData } = await registerWithPaymentMutation({
      variables: {
        input,
      },
    });

    const { token, errors } = registerData?.userRegister || {};

    if (!isEmpty(errors)) {
      Sentry.captureException(errors);
      const [fieldErrors, otherErrors] = partition(
        errors?.filter(notEmpty),
        (e) => !~['__all__', 'payment', 'captcha', 'phone_not_verified'].indexOf(e.field),
      );

      if (fieldErrors && fieldErrors.length) {
        Validator.setResponseErrors(Validator.throwSubmissionError, fieldErrors || []);
      }

      if (otherErrors) {
        // check if phone_not_verified then show modal
        const phoneNotVerifiedError = otherErrors.find((e) => e.field === 'phone_not_verified');
        if (phoneNotVerifiedError) {
          showModal({
            modalType: 'RegisterPhoneVerify',
            modalProps: {
              unconfirmedUserId: unconfirmedUserData?.unconfirmedUser?.id,
              phoneNumberVerificationSid:
                unconfirmedUserData?.unconfirmedUser?.phoneNumberVerificationSid,
              phone: values.phone,
              callback: (phone) => {
                handleRegisterWithPayment({ ...values, phone }, nonce);
              },
            },
          });
        } else {
          setBraintreeUniqueId(+new Date());
          Sentry.captureException(
            new Error(`Registration failed. Other errors: ${JSON.stringify(otherErrors)}`),
          );
          showRegisterFailedModal([
            { field: 'all', messages: [t('Registration failed. Please contact support')] },
          ]);
        }
      }
      Sentry.captureException(new Error(`Registration failed. Errors: ${JSON.stringify(errors)}`));
      showRegisterFailedModal([
        { field: 'all', messages: [t('Registration failed. Please contact support')] },
      ]);
    } else {
      if (token) {
        cookie.save('sessionid', token, {
          path: '/',
          domain: config.baseUrl,
        });
      }
      (window as Window).location = '/app/welcome?firstVisit';
    }
  };

  const handleRegister = (values) => {
    const input: RegisterCompleteWizardInput = {
      email: unconfirmedUserData?.unconfirmedUser?.email || '',
      fullName: values?.[FIELD_NAMES.FULL_NAME],
      password: values?.[FIELD_NAMES.PASSWORD],
      organizationName: values?.[FIELD_NAMES.ORG_URL],
      organizationUrl: values?.[FIELD_NAMES.ORG_URL],
      targetDomain: values?.[FIELD_NAMES.ORG_URL],
      selectedCountry: values?.[FIELD_NAMES.ORG_COUNTRY],
    };

    return registerMutation({
      variables: {
        input,
      },
    })
      .then((res) => {
        const errors = extractQueryFormErrors(res);
        if (!isEmpty(errors)) throw 'Registration error';
        (window as Window).location = '/app/welcome?firstVisit';
      })
      .catch((err) => {
        Sentry.captureException(err);
        showRegisterFailedModal([
          { field: 'all', messages: [t('Registration failed. Please contact support')] },
        ]);
      });
  };

  const handleSubmit = (values) => {
    if (signupType === TRIAL_CC_AND_PAY || signupType === PAY) {
      return braintreeInstance?.requestPaymentMethod(
        {
          threeDSecure: {
            challengeRequested: true,
            amount: pricingPlan ? pricingPlan.priceMonthly : 1,
          },
        },
        (braintreeError, payload) => {
          if (braintreeError) {
            try {
              throw braintreeError;
            } catch (e) {
              Sentry.captureException(e);
            }

            setBraintreeUniqueId(+new Date());

            showModal({
              modalType: 'PaymentFailed',
              modalProps: {
                errorType: braintreeError.message,
              },
            });
            return;
          }

          return handleRegisterWithPayment(values, payload.nonce);
        },
      );
    }
    return handleRegister(values);
  };

  return { handleSubmit };
};
