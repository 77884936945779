import { useEffect, useState } from 'react';
import { TableID } from 'Types/Table';
import * as tableStoreActions from '../../store/TableStore';
import type { TableStoreType } from '../../store/TableStore';

export const useTableStore = (
  tableName: TableID,
  initOnEmpty: boolean = false,
  onLoad?: Function,
) => {
  const [tableStore, setTableStore] = useState<TableStoreType | null>(null);

  useEffect(() => {
    const loadedTableStore = tableStoreActions.getTableStore(tableName, undefined, !initOnEmpty);
    let unsubscribe;
    if (loadedTableStore) {
      setTableStore(loadedTableStore);
      onLoad?.();
    } else {
      unsubscribe = tableStoreActions.subscribeToTableStore(tableName, (store) => {
        setTableStore(store);
        onLoad?.();
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, []);
  return tableStore;
};
