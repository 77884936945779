export type ItemType = 'group' | 'domain' | 'folder' | 'tag';
export const folderSeparator = '¦';
export const generateSelectId = (
  type: ItemType,
  id: string | number,
  path?: string,
  isDynamicTag?: boolean,
): string => {
  switch (type) {
    case 'domain':
    case 'folder':
      return `${path}${folderSeparator}${id}`;
    case 'tag':
      return `${path}${folderSeparator}${id}${isDynamicTag ? 'Ỽ' : ''}`;
    default:
      return `${id}`;
  }
};
