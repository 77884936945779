import { Group } from '@mantine/core';
import { IconChartHistogram } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import { AccContextToolbar } from 'Components/AccContextToolbar/AccContextToolbar';
import { useModal } from 'Hooks/base/useModal';
import { TrackingKey, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';

type Props = {
  selectedDomains?: string[];
  deselectAll: () => void;
};

const Toolbar = ({ selectedDomains, deselectAll }: Props) => {
  const trackEvent = useMixpanel();
  const { showModal } = useModal();
  const handleOpenCompareModal = () => {
    trackEvent(TrackingKey.OpenDomainsComparisonModal);
    showModal({
      modalType: 'DomainsComparison',
      modalTheme: 'light',
      modalProps: {
        selectedDomains,
      },
    });
  };
  const selectedQty = selectedDomains?.length ?? 0;

  return (
    <AccContextToolbar
      enabled={!!selectedQty}
      onDisable={deselectAll}
      items={selectedQty}
      singularLabel={'domain selected'}
      pluralLabel={'domains selected'}
    >
      <Group gap={0}>
        {/* <ReportAction /> */}
        <AccButton
          variant="tertiary"
          c="white"
          noTracking
          leftSection={<IconChartHistogram size={18} />}
          onClick={handleOpenCompareModal}
        >
          {t('Compare')}
        </AccButton>
      </Group>
    </AccContextToolbar>
  );
};
export default Toolbar;
