import { lazy, useMemo } from 'react';
import { RouterProvider } from 'react-router';
import GoogleAccountsCallbackPage from 'Components/User/GoogleAccounts/Callback';
import { AuthRoutePath, RoutePath } from 'Constants/Routes';
import { useFingerprintTracking } from 'Hooks/useFingerprint';
import useOnUserChange from 'Hooks/useOnUserChange';
import AccountSettingsPage from 'Pages/AccountSettings';
import PendingRequestsPage from 'Pages/Accounts/PendingRequests';
import SubAccountsPage from 'Pages/Accounts/SubAccounts';
import AccountsDomainsManagementPage from 'Pages/Accounts/TransferMultiAccountDomain';
import AccuApiPage from 'Pages/AccuApi';
import ApiPage from 'Pages/ApiPage';
import LoginPage from 'Pages/Auth/LoginPage/LoginPage';
import PasswordSetupPage from 'Pages/Auth/PasswordSetupPage/PasswordSetupPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage/ResetPasswordPage';
import CancelPage from 'Pages/Billing/CancelPlan';
import CheckoutFormPage from 'Pages/Billing/Checkout';
import Subscription from 'Pages/Billing/Subscription/Subscription';
import ConnectedAccountsPage from 'Pages/ConnectedAccounts';
import DomainSettings from 'Pages/DomainSettings';
import DomainsPage from 'Pages/Domains';
import NotFoundPage from 'Pages/ErrorPages/404';
import SystemErrorPage from 'Pages/ErrorPages/500';
import AccessDenied from 'Pages/ErrorPages/AccessDenied';
import AccountBlockedPage from 'Pages/ErrorPages/Blocked';
import FailedPaymentPage from 'Pages/ErrorPages/FailedPayment';
import PlanExpiredPage from 'Pages/ErrorPages/PlanExpired';
import TrialExpiredPage from 'Pages/ErrorPages/TrialExpired';
import ExternalAccessPage from 'Pages/ExternalAccess';
import GroupsPage from 'Pages/Groups';
import ImportPage from 'Pages/Import';
import ImportGSC from 'Pages/Import/GSC';
import ImportValidationPage from 'Pages/Import/Validation';
import IntegrationApiPage from 'Pages/IntegrationApi';
import IntegrationsPage from 'Pages/Integrations';
import InvoicesPage from 'Pages/Invoices';
import KeywordDiscoveryPage from 'Pages/KeywordDiscovery';
import KeywordOverusePage from 'Pages/KeywordOverusePage/KeywordOverusePage';
import KeywordsCompetitorsPage from 'Pages/Keywords/Competitors/CompetitorsPage';
import { Groupings } from 'Pages/Keywords/Groupings/Groupings';
import { LandingPage } from 'Pages/Keywords/LandingPage/LandingPage';
import { NextOverviewPage } from 'Pages/Keywords/Overview';
import KeywordsSerp from 'Pages/Keywords/SERP';
import KeywordsTable from 'Pages/Keywords/Table/KeywordsPage';
import NotesList from 'Pages/NotesList';
import ProfilePage from 'Pages/Profile/Profile';
import PublicReportPage from 'Pages/PublicReport/PublicReportPage';
import RegisterPage from 'Pages/Register';
import ReportTemplateBuilderClonePage from 'Pages/ReportTemplateBuilder/CloneTemplate';
import ReportTemplateBuilderPage from 'Pages/ReportTemplateBuilder/CreateTemplate';
import ReportTemplateBuilderEditPage from 'Pages/ReportTemplateBuilder/EditTemplate';
import ReportTemplatesPage from 'Pages/ReportTemplatesOverview';
import ReportsOverviewGeneratedPage from 'Pages/ReportsOverview/GeneratedReports';
import ReportsOverviewScheduledPage from 'Pages/ReportsOverview/ScheduledReports';
import ResearchPage from 'Pages/Research';
import ScheduledReportBuilder from 'Pages/ScheduledReportBuilder';
import SelectPlanPage from 'Pages/SelectPlan';
import SiteMappingPage from 'Pages/SiteMapping';
import UsersPage from 'Pages/Users';
import WalletPage from 'Pages/Wallet';
import WelcomePage from 'Pages/Welcome';
import WorkspacesPage from 'Pages/Workspaces';
import ImportExamples from 'Pages/importExamples/ImportExamples';
import {
  AFFILIATE_FILTER_SET,
  DOMAINS_FILTER_SET,
  EMPTY_FILTER_SET,
  IMPORT_GSC_FILTER_SET,
  KEYDIS_FILTER_SET,
  KEYWORDS_FILTER_SET,
  NOTES_FILTER_SET,
  PUBLIC_REPORT_FILTER_SET,
  SALES_FILTER_SET,
  SITE_MAPPING_FILTER_SET,
  TAG_CLOUD_FILTER_SET,
} from 'Types/FilterSet';
import { Redirect } from 'Utilities/Router';
import { AppRouteItem, RouteType, useAppRouter } from 'Utilities/Router/hooks/useAppRouter';
import { t } from 'Utilities/i18n';

const ERROR_ID = '9b19ac26-7fbf-4a6c-bafe-944588a63550';

// Route to test sentry errors
const TestErrorPage = () => {
  throw new Error(`Error ${ERROR_ID} test error in sentry`);
  return <h1>Error should not be visible</h1>;
};

export const useAppDeclarations = (): AppRouteItem[] => {
  return useMemo<AppRouteItem[]>(
    () => [
      {
        path: `/error-test-${ERROR_ID}`, // `/error-test-9b19ac26-7fbf-4a6c-bafe-944588a63550`
        component: TestErrorPage,
        type: RouteType.PUBLIC,
        title: () => t('Error Page'),
      },
      {
        path: AuthRoutePath.LOGIN,
        component: LoginPage,
        type: RouteType.PUBLIC,
        title: () => t('Log in'),
        e2e: {
          enabled: true,
          title: 'Sign in to AccuRanker',
        },
      },
      {
        path: AuthRoutePath.FORGOT_PASSWORD,
        component: ResetPasswordPage,
        type: RouteType.PUBLIC,
        title: () => t('Reset Password'),
        e2e: {
          enabled: true,
        },
      },
      {
        paths: [
          `${AuthRoutePath.CHANGE_PASSWORD}/:uidb/:token`,
          `${AuthRoutePath.CHANGE_PASSWORD}/:uidb`,
          `${AuthRoutePath.CHANGE_PASSWORD}/`,
        ],
        component: PasswordSetupPage,
        type: RouteType.PUBLIC,
        title: () => t('Change Password'),
        e2e: {
          enabled: true,
          path: `${AuthRoutePath.CHANGE_PASSWORD}/uidb-sample/token-sample`,
        },
      },
      {
        paths: ['/checkout/:cycle/:id/:coupon', '/checkout/:cycle/:id'],
        component: CheckoutFormPage,
        type: RouteType.PRIVATE,
        title: () => `${t('Account')} / ${t('Subscription')} / ${t('Checkout')}`,
        privateRouteConfig: {
          isTrialExpiredOk: true,
          isPlanExpiredOk: true,
          isFailedPaymentOk: true,
        },
        shell: {
          hideShell: true,
        },
      },
      {
        path: '/welcome',
        component: WelcomePage,
        type: RouteType.PRIVATE,
        title: () => t('Welcome'),
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
        },
      },
      {
        paths: ['/affiliate', '/affiliate/:filter'],
        component: lazy(() => import('Pages/Affiliate')),
        type: RouteType.PRIVATE,
        title: () => t('Affiliate'),
        filterSet: AFFILIATE_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/sales/organization/:id',
        component: lazy(() => import('Pages/Sales/Organization')),
        title: () => `${t('Sales')} / ${t('Organization')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/plans/create', '/sales/plans/create/:filter'],
        component: lazy(() => import('Pages/Sales/CreatePlan')),
        title: () => `${t('Sales')} / ${t('Search')} / ${t('Create')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/plans', '/sales/plans/:filter'],
        component: lazy(() => import('Pages/Sales/Plans')),
        title: () => `${t('Sales')} / ${t('Plans')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/customers', '/sales/customers/:filter'],
        component: lazy(() => import('Pages/Sales/Customers')),
        title: () => `${t('Sales')} / ${t('Customers')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/search', '/sales/search/:filter'],
        component: lazy(() => import('Pages/Sales/Search')),
        title: () => `${t('Sales')} / ${t('Search')}`,
        filterSet: SALES_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/importer', '/sales/importer/:filter'],
        component: lazy(() => import('Pages/Sales/Importer')),
        title: () => `${t('Sales')} / ${t('Importer')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/mails', '/sales/mails/:filter'],
        component: lazy(() => import('Pages/Sales/Mails')),
        title: () => `${t('Sales')} / ${t('Mails')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/dashboard', '/sales/dashboard/:filter'],
        component: lazy(() => import('Pages/Sales/Trials')),
        title: () => `${t('Sales')} / ${t('Trials')}`,
        filterSet: SALES_FILTER_SET,
        shell: {
          showFilters: false,
          showSegments: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/tools', '/sales/tools/:filter'],
        component: lazy(() => import('Pages/Sales/Tools')),
        title: () => `${t('Sales')} / ${t('Tools')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/invoice/customers', '/sales/invoice/customers/:filter'],
        component: lazy(() => import('Pages/Sales/Invoice/Customers')),
        title: () => `${t('Sales')} / ${t('Invoice Customers')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/sales/reconcile_payments', '/sales/reconcile_payments/:filter'],
        component: lazy(() => import('Pages/Sales/Reconcile')),
        filterSet: EMPTY_FILTER_SET,
        title: () => `${t('Sales')} / ${t('Reconcile Payments')}`,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/profile', '/profile/:filter'],
        component: ProfilePage,
        title: () => t('Profile'),
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
          isNoPermissionOk: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/notes', '/notes/:filter'],
        component: NotesList,
        title: () => t('Notes'),
        filterSet: NOTES_FILTER_SET,
        shell: {
          showFilters: false,
          showFiltersShell: true,
          showBreadcrumbs: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/settings', '/settings/:filter'],
        component: DomainSettings,
        title: () => t('Settings'),
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
          showFiltersShell: true,
          showBreadcrumbs: true,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/accounts/invoices/:id',
        component: InvoicesPage,
        title: () => `${t('Account')} / ${t('Subscription')} / ${t('Invoices')}`,
        filterSet: EMPTY_FILTER_SET,
        privateRouteConfig: {
          isAccuApiUserOK: true,
          orgAdminRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/accounts/domains', '/accounts/domains/:filter'],
        component: AccountsDomainsManagementPage,
        title: () => `${t('Sub-accounts')} / ${t('Transfer & Copy Domains')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/accounts/requests', '/accounts/requests/:filter'],
        component: PendingRequestsPage,
        title: () => `${t('Sub-accounts')} / ${t('Accounts Requests')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/accounts',
        component: SubAccountsPage,
        title: () => t('Sub-Accounts'),
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        e2e: {
          enabled: true,
          titleType: 'breadcrumb',
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/account/googleoauth/callback',
        component: GoogleAccountsCallbackPage,
        title: () => t('Auth Callback'),
        shell: {
          hideShell: true,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/account/users/:filter', '/account/users'],
        component: UsersPage,
        title: () => `${t('Account')} / ${t('Users')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          orgAdminRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/account/workspaces/:filter', '/account/workspaces'],
        component: WorkspacesPage,
        title: () => `${t('Account')} / ${t('Workspaces')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          superuserRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: [RoutePath.ACCOUNT_EXTERNAL_ACCESS, RoutePath.ACCOUNT_REQUEST_EXTERNAL_REQUEST],
        component: ExternalAccessPage,
        title: () => `${t('Account')} / ${t('External Access')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          orgAdminRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: [RoutePath.ACCOUNT_KEYWORD_OVERUSE, `${RoutePath.ACCOUNT_KEYWORD_OVERUSE}/:filter`],
        component: KeywordOverusePage,
        title: () => `${t('Account')} / ${t('Keyword usage')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          orgAdminRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/account/billing/cancel/Wdub3p/',
        component: CancelPage,
        title: () => `${t('Account')} / ${t('Subscription')} / ${t('Cancel')}`,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
          orgAdminRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/account/billing', '/account/billing/:filter'],
        component: Subscription,
        title: () => `${t('Account')} / ${t('Subscription')}`,
        filterSet: EMPTY_FILTER_SET,
        privateRouteConfig: {
          isTrialExpiredOk: true,
          isPlanExpiredOk: true,
          isFailedPaymentOk: true,
          isAccuApiUserOK: true,
          orgAdminRestriction: true,
        },
        shell: {
          showFilters: false,
        },
        e2e: {
          enabled: true,
          titleType: 'nested-breadcrumb',
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/billing/package/select',
        component: SelectPlanPage,
        title: () => `${t('Account')} / ${t('Subscription')} / ${t('Select Plan')}`,
        privateRouteConfig: {
          isTrialExpiredOk: true,
          isPlanExpiredOk: true,
          isFailedPaymentOk: true,
          orgAdminRestriction: true,
        },
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/account/connected', '/account/connected/:filter'],
        component: ConnectedAccountsPage,
        title: () => `${t('Account')} / ${t('Connected Integrations')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          orgAdminRestriction: true,
        },
      },
      {
        path: '/account/wallet',
        component: WalletPage,
        type: RouteType.PRIVATE,
        title: () => `${t('Account')} / ${t('Wallet')}`,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          orgAdminRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/account/accu_api',
        component: AccuApiPage,
        title: () => `${t('Account')} / ${t('AccuAPI')}`,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
          accessRestriction: 'accuApi',
          writeOnlyRestriction: true,
        },
      },
      {
        path: '/account',
        component: AccountSettingsPage,
        type: RouteType.PRIVATE,
        title: () => `${t('Account')} / ${t('Settings')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          isAccuApiUserOK: true,
          writeOnlyRestriction: true,
        },
      },
      {
        paths: ['/keywords/import/gsc/:filter', '/keywords/import/gsc'],
        component: ImportGSC,
        type: RouteType.PRIVATE,
        title: () => t('Import from Google Search Console'),
        filterSet: IMPORT_GSC_FILTER_SET,
        shell: {
          showFilters: true,
          showFiltersShell: true,
          showBreadcrumbs: true,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        paths: ['/keywords/overview/:filter', '/keywords/overview'],
        component: () => <NextOverviewPage />,
        type: RouteType.PRIVATE,
        title: () => t('Overview'),
        filterSet: KEYWORDS_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        paths: ['/keywords/keyword_discovery/:filter', '/keywords/keyword_discovery'],
        component: KeywordDiscoveryPage,
        type: RouteType.PRIVATE,
        title: () => t('Keyword Discovery'),
        filterSet: KEYDIS_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        paths: ['/research/:filter', '/research'],
        component: ResearchPage,
        type: RouteType.PRIVATE,
        title: () => t('Keyword Discovery'),
        filterSet: KEYDIS_FILTER_SET,
        strict: false,
      },
      {
        paths: ['/keywords/list/:filter', '/keywords/list'],
        component: KeywordsTable,
        type: RouteType.PRIVATE,
        title: () => t('Keywords'),
        filterSet: KEYWORDS_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        paths: ['/keywords/competitors/:filter', '/keywords/competitors'],
        component: KeywordsCompetitorsPage,
        type: RouteType.PRIVATE,
        title: () => t('Competitors'),
        filterSet: KEYWORDS_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        paths: ['/keywords/serp/:filter', '/keywords/serp'],
        component: KeywordsSerp,
        type: RouteType.PRIVATE,
        title: () => t('SERP'),
        filterSet: KEYWORDS_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        paths: ['/keywords/tags/:filter', '/keywords/tags'],
        component: Groupings,
        type: RouteType.PRIVATE,
        title: () => t('Tag Cloud'),
        filterSet: TAG_CLOUD_FILTER_SET,
        strict: false,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        paths: ['/keywords/landing-pages/:filter', '/keywords/landing-pages'],
        component: LandingPage,
        type: RouteType.PRIVATE,
        title: () => t('Landing Pages'),
        filterSet: KEYWORDS_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/keywords/sitemapping/:filter', '/keywords/sitemapping'],
        component: SiteMappingPage,
        title: () => t('Organic Site Explorer'),
        filterSet: SITE_MAPPING_FILTER_SET,
        shell: {
          showBreadcrumbs: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/groups/:filter', '/groups'],
        component: GroupsPage,
        title: () => t('Groups'),
        filterSet: DOMAINS_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/integrations/api', '/integrations/api/:filter'],
        component: IntegrationApiPage,
        title: () => `${t('Integrations')} / ${t('API Filters')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/integrations',
        component: IntegrationsPage,
        title: () => t('Integrations'),
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        paths: ['/reports/schedule', '/reports/schedule/edit/:id'],
        component: ScheduledReportBuilder,
        title: () => `${t('Reporting')} / ${t('Scheduled Reports')} / ${t('Edit')}`,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/reports/templates/builder/edit/:id',
        component: ReportTemplateBuilderEditPage,
        title: () => `${t('Reporting')} / ${t('Report Templates')} / ${t('Edit')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/reports/templates/builder/clone/:id',
        component: ReportTemplateBuilderClonePage,
        title: () => `${t('Reporting')} / ${t('Report Templates')} / ${t('Clone')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/reports/templates/builder',
        component: ReportTemplateBuilderPage,
        title: () => `${t('Reporting')} / ${t('Report Templates')} / ${t('Create')}`,
        filterSet: EMPTY_FILTER_SET,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/reports/templates',
        component: ReportTemplatesPage,
        title: () => `${t('Reporting')} / ${t('Report Templates')}`,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/reports/generated',
        component: ReportsOverviewGeneratedPage,
        title: () => `${t('Reporting')} / ${t('Generated Reports')}`,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/reports/scheduled',
        component: ReportsOverviewScheduledPage,
        title: () => `${t('Reporting')} / ${t('Scheduled Reports')}`,
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.DEFAULT,
        paths: [
          '/reports/public/:domainSlug/:viewkey',
          '/reports/public/:domainSlug/:viewkey/:filter',
        ],
        component: PublicReportPage,
        title: () => `${t('Report for domain')}`,
        filterSet: PUBLIC_REPORT_FILTER_SET,
      },
      {
        type: RouteType.DEFAULT,
        path: '/api',
        component: ApiPage,
        title: () => t('API'),
        strict: false,
        shell: {
          hideShell: true,
        },
      },
      {
        type: RouteType.DEFAULT,
        path: '/api/v4/write/docs',
        component: ApiPage,
        strict: false,
        title: () => t('API v4 Write'),
        shell: {
          hideShell: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/register/:id',
        component: RegisterPage,
        title: () => t('Activate your trial account'),
        strict: false,
        privateRouteConfig: {
          forceUnauthenticated: true,
          isRegisterPage: true,
          isAccuApiUserOK: true,
          // isNoPermissionOk: true, // needed for signup_type 3
        },
        shell: {
          hideShell: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/error/blocked',
        component: AccountBlockedPage,
        title: () => t('Account Blocked'),
        privateRouteConfig: {
          isTrialExpiredOk: true,
          isPlanExpiredOk: true,
          isNoPermissionOk: true,
          isFailedPaymentOk: true,
        },
        shell: {
          hideShell: true,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/error/trial-expired',
        component: TrialExpiredPage,
        title: () => t('Trial has expired'),
        privateRouteConfig: {
          isTrialExpiredOk: true,
        },
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/error/plan-expired',
        component: PlanExpiredPage,
        title: () => t('Plan has expired'),
        privateRouteConfig: {
          isPlanExpiredOk: true,
        },
        shell: {
          showFilters: false,
        },
      },
      {
        type: RouteType.PRIVATE,
        path: '/error/failed-payment',
        component: FailedPaymentPage,
        title: () => t('Payment failed'),
        privateRouteConfig: {
          isTrialExpiredOk: true,
          isPlanExpiredOk: true,
        },
        shell: {
          hideShell: true,
        },
      },
      {
        paths: ['/domains/:filter', '/domains'],
        component: DomainsPage,
        type: RouteType.PRIVATE,
        title: () => t('Domains'),
        shell: {
          showFilters: false,
        },
        filterSet: DOMAINS_FILTER_SET,
      },
      {
        path: '/import/validate/:id',
        component: ImportValidationPage,
        type: RouteType.PRIVATE,
        title: () => `${t('Import')} / ${t('Validation')}`,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        path: '/import/examples',
        component: ImportExamples,
        type: RouteType.PRIVATE,
        title: () => `${t('Import')} / ${t('Examples')}`,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },

      {
        path: '/import',
        component: ImportPage,
        type: RouteType.PRIVATE,
        title: () => t('Import'),
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          writeOnlyRestriction: true,
        },
      },
      {
        paths: ['/', '/:filter'],
        component: DomainsPage,
        test: true,
        type: RouteType.PRIVATE,
        title: () => t('Dashboard'),
        filterSet: DOMAINS_FILTER_SET,
        shell: {
          showFilters: false,
        },
      },
      {
        paths: ['/account/adobe', '/account/googleoauth', '/account/googleoauth'],
        component: () => <Redirect to="/account/connected" />,
        disableAnalytics: true,
        redirect: true,
        title: () => '',
      },
      {
        path: '/error/500/:eventId',
        component: SystemErrorPage,
        title: () => `${t('Error')} / ${t('Something Went Wrong')}`,
      },
      {
        paths: ['/error/no-access'],
        component: AccessDenied,
        title: () => t('Access Denied'),
        type: RouteType.PRIVATE,
        shell: {
          showFilters: false,
        },
        privateRouteConfig: {
          isNoPermissionOk: true,
        },
      },
      {
        paths: ['/error/404', '*'],
        component: NotFoundPage,
        title: () => t('Page not found'),
      },
    ],
    [],
  );
};

export const App = () => {
  const subRoutes = useAppDeclarations();
  const routes = useAppRouter(subRoutes);
  useOnUserChange();
  useFingerprintTracking();
  return <RouterProvider router={routes} />;
};
