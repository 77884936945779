import { useIntl } from 'react-intl';
import { Flex } from '@mantine/core';
import cn from 'classnames';
import FormattedNumber from 'Components/FormatNumber/new';
import { t } from 'Utilities/i18n';
import ArrowDown from 'icons/arrow-down.svg';
import ArrowUp from 'icons/arrow-up.svg';
import AccTooltip from '../AccTooltip/AccTooltip';
import { intlFormatNumber } from '../FormatNumber/helpers';
import './value-indicator.scss';

type Props = {
  beforeValue: number | null;
  afterValue: number | null;
  negate?: boolean;
  style?: 'decimal' | 'currency' | 'percent';
  currency?: string;
  noCompareString?: string;
  maxDecimals?: number;
  /** If set to false: zero change between before- and after value will render an empty field  */
  renderZeroChangeValues?: boolean;
  /** Render null or undefined change as hyphen */
  nullishHyphen?: boolean;
};

const ValueIndicator = (props: Props) => {
  const intl = useIntl();
  const {
    beforeValue,
    afterValue,
    negate = false,
    style = 'decimal',
    currency,
    noCompareString = '0',
    maxDecimals,
    renderZeroChangeValues = true,
    nullishHyphen = false,
  } = props;

  const percentageMultiplier = style === 'percent' ? 100 : 1;

  let change: number | undefined;
  let increased: boolean | undefined;
  let decreased: boolean | undefined;
  if ((beforeValue || beforeValue === 0) && (afterValue || afterValue === 0)) {
    // Make sure to round the change to the correct number of decimals
    change =
      typeof maxDecimals === 'number'
        ? parseFloat(
            (afterValue * percentageMultiplier - beforeValue * percentageMultiplier).toFixed(
              maxDecimals,
            ),
          ) / percentageMultiplier
        : afterValue - beforeValue;
    if (Math.round(change) === 0 && style !== 'percent' && !maxDecimals) {
      increased = false;
      decreased = false;
    } else {
      increased = (negate ? change < 0 : change > 0) && afterValue !== beforeValue;
      decreased = (negate ? change > 0 : change < 0) && afterValue !== beforeValue;
    }
  } else if (afterValue) {
    increased = negate;
    decreased = !increased;
  } else if (beforeValue) {
    increased = !negate;
    decreased = !increased;
  }

  const numberOfDigits = style === 'percent' ? maxDecimals ?? 1 : maxDecimals ?? 0; // Default to 1 decimal for percent, 0 otherwise

  const resultValue = beforeValue
    ? intlFormatNumber(intl)({
        value: beforeValue,
        style,
        currency,
        minimumFractionDigits: numberOfDigits,
        maximumFractionDigits: numberOfDigits,
      })
    : noCompareString;
  let tooltip = `${t('Compare value:')} ${resultValue}`;
  if (currency) {
    tooltip += ` ${currency}`;
  }

  const renderZero = change === 0 && renderZeroChangeValues;

  return (
    <AccTooltip tooltip={tooltip}>
      <Flex
        justify="space-between"
        className={cn('value-indicator', {
          'value-indicator--increased': increased,
          'value-indicator--decreased': decreased,
        })}
      >
        <div
          className={cn(
            increased ? 'increase-indicator-icon' : decreased ? 'decrease-indicator-icon' : '',
          )}
          style={{
            WebkitMaskImage: increased ? `url(${ArrowUp})` : decreased ? `url(${ArrowDown})` : '',
            maskImage: increased ? `url(${ArrowUp})` : decreased ? `url(${ArrowDown})` : '',
          }}
        />
        {(!!change || renderZero) && (
          <FormattedNumber
            value={Math.abs(change || 0)}
            style={style}
            currency={currency}
            minimumFractionDigits={numberOfDigits}
            maximumFractionDigits={numberOfDigits}
            className="indicator-number"
          />
        )}
        {/* Add a hyphen when no change data is available  */}
        {typeof change !== 'number' && nullishHyphen && <>-&nbsp;&nbsp;</>}
      </Flex>
    </AccTooltip>
  );
};

export default ValueIndicator;
