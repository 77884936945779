import { IconTableOptions } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import { TableSize } from 'Components/DataTable';
import { useModal } from 'Hooks/base/useModal';
import {
  useHasMultiGSCConnectAccess,
  useHasMultiGaConnectAccess,
} from 'Hooks/data/organization/useOrganizationInfo';
import { useUser } from 'Hooks/data/user/useUser';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { TableIDs } from 'Types/Table';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import DomainsViewMode from './DomainsViewMode';
import { DisplayMode, PauseSwitch } from './PauseSwitch';
import { onAddGroupCallback } from './support/onAddGroupCallback';
import { useRefetchGroupData } from './support/useRefetchGroupData';

type Props = {
  viewMode: TableSize;
  display: DisplayMode;
  setDisplay: (value: DisplayMode) => void;
  handleSetGroup?: (groupId: number) => void;
};
const DomainsActionBar = ({ viewMode, display, setDisplay, handleSetGroup }: Props) => {
  const { showModal } = useModal();
  const refetchGroupData = useRefetchGroupData();
  const user = useUser();
  const userDomainsWithNoGA =
    (user.organization?.numberOfDomains || 0) - (user.organization?.numberOfDomainsWithGa || 0);

  const userDomainsWithNoGSC =
    (user.organization?.numberOfDomains || 0) - (user.organization?.numberOfDomainsWithGwt || 0);

  const isMultiConnectEnabled = useHasMultiGaConnectAccess();

  const isMultiGSCConnectEnabled = useHasMultiGSCConnectAccess();

  const showBulkGAButton = userDomainsWithNoGA > 1 && isMultiConnectEnabled;

  const showBulkGSCButton = userDomainsWithNoGSC > 1 && isMultiGSCConnectEnabled;

  const onAddGroup = (groupId: string, data: any, tries: number) => {
    onAddGroupCallback({
      id: groupId,
      result: data,
      tries,
      refetch: refetchGroupData,
      handleSetGroup,
    });
  };

  const handleAddDomain = () => {
    showModal({
      modalType: 'BuildDomain',
      modalTheme: 'light',
    });
  };
  const handleAddGroup = () => {
    showModal({
      modalType: 'AddGroup',
      modalTheme: 'light',
      modalProps: {
        refetch: refetchGroupData,
        onAddCallback: onAddGroup,
      },
    });
  };

  const handleConnectToAnalytics = () => {
    showModal({
      modalType: 'ConnectToGA',
      modalTheme: 'light',
      modalProps: {
        bulkConnect: true,
      },
    });
  };

  const handleConnectToGSC = () =>
    showModal({
      modalType: 'ConnectToGSC',
      modalTheme: 'light',
      modalProps: {
        bulkConnect: true,
      },
    });

  const handleOpenTableSettings = () => {
    showModal({
      modalType: 'TableSettingsModal',
      modalTheme: 'light',
      modalProps: {
        tableName: TableIDs.DOMAINS,
      },
    });
  };

  const isCardMode = viewMode === TableSize.CARD;

  return (
    <ActionbarContainer mt={isCardMode ? 0 : 'lg'}>
      <AccessControl>
        <Actions.AddAction
          key="add domain"
          variant="primary"
          label={t('Add domain')}
          onClick={handleAddDomain}
        />
        <Actions.AddAction
          key="add group"
          variant="secondary"
          label={t('Add group')}
          onClick={handleAddGroup}
        />
        {showBulkGAButton && (
          <Actions.ConnectToAnalyticsAction
            label={t('Connect domains to Analytics')}
            key="addAnalytics"
            onClick={handleConnectToAnalytics}
          />
        )}
        {showBulkGSCButton && (
          <Actions.ConnectToGSCAction
            label={t('Connect domains to Search Console')}
            key="addGSC"
            onClick={handleConnectToGSC}
          />
        )}
      </AccessControl>
      <ActionbarContainer.Sidebar>
        {display === 'domains' && [TableSize.DEFAULT, TableSize.COMPACT].includes(viewMode) ? (
          <AccButton
            leftSection={<IconTableOptions size={20} />}
            trackingKey={TrackingKey.TableSettings}
            variant="tertiary"
            onClick={handleOpenTableSettings}
          >
            {t('Configure columns')}
          </AccButton>
        ) : (
          <div style={{ marginLeft: 'auto' }} />
        )}
        <DomainsViewMode tableName={TableIDs.DOMAINS} isSimple={display !== 'domains'} />
        <PauseSwitch
          display={display}
          canPause={!!user.organization?.activePlan?.featureCanPause}
          onChange={setDisplay}
        />
      </ActionbarContainer.Sidebar>
    </ActionbarContainer>
  );
};

export default DomainsActionBar;
