import { DataTable, MIN_TABLE_HEIGHT, QueryOrder, useDataCopyAction } from 'Components/DataTable';
import * as Actions from 'Pages/Layout/ActionsMenu/Actions';
import { TableIDs } from 'Types/Table';
import {
  KeywordTableColumnIDs,
  useFetchSingleKeywordHistory,
  useKeywordHistoryColumns,
  useShowHistoryReportModal,
} from './support';

type Props = {
  keywordId: string;
};

const HistoryDataTableID = TableIDs.KEYWORD_HISTORY;

export const HistoryDataTable = (props: Props) => {
  const fetchData = useFetchSingleKeywordHistory(props.keywordId);
  const columns = useKeywordHistoryColumns();
  const showReport = useShowHistoryReportModal(props.keywordId);
  const handleCopyTable = useDataCopyAction(HistoryDataTableID, columns);
  return (
    <>
      <div className="table-actions-left">
        <Actions.CopyAction onClick={handleCopyTable} />
        <Actions.ReportAction onClick={showReport} />
      </div>
      <DataTable
        tableId={HistoryDataTableID}
        fetchData={fetchData}
        columns={columns}
        expandRowColumnIds={[KeywordTableColumnIDs.RANK, KeywordTableColumnIDs.URL]}
        defaultOrdering={{ order: QueryOrder.DESC, orderBy: KeywordTableColumnIDs.SEARCH_DATE }}
        tableMinHeight={MIN_TABLE_HEIGHT}
        syncFilters={false}
        scrollOnPageChange={false}
      />
    </>
  );
};
