import { useMemo } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import {
  IconArrowsTransferDown,
  IconArrowsTransferUp,
  IconCaretDown,
  IconCaretUp,
} from '@tabler/icons-react';
import cn from 'classnames';
import { AccDropdownMenu, DropdownItem } from 'Components/AccDropdownMenu';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { QueryOrder } from 'Components/DataTable';
import AccTitle from 'Components/Title/AccTitle';
import { t } from 'Utilities/i18n';
import { TableColumnConfig } from '../utils/useFoldersTableConfig';
import styles from '../../groupings.module.scss';

type Props = {
  header: TableColumnConfig;
  content: string;
  setOrderBy: (orderBy?: string, order?: QueryOrder.ASC | QueryOrder.DESC) => void;
  orderBy: string;
  order: string;
};
const HeaderColumn = (props: Props) => {
  const { content, header, setOrderBy, orderBy, order } = props;

  const theme = useMantineTheme();

  const dropdownItems = useMemo((): DropdownItem[] => {
    return [
      {
        label: t('%s (high to low)', header.title),
        value: header.orderBy,
        onSelect: (val) => setOrderBy(val?.value as string, QueryOrder.DESC),
        icon: <IconCaretDown size={18} color={theme.colors.gray[7]} />,
      },
      {
        label: t('%s (low to high)', header.title),
        value: header.orderBy,
        onSelect: (val) => setOrderBy(val?.value as string, QueryOrder.ASC),
        icon: <IconCaretUp size={18} color={theme.colors.gray[7]} />,
      },
      {
        label: t('Changes in %s (high to low)', header.title),
        value: header.orderByChange,
        onSelect: (val) => setOrderBy(val?.value as string, QueryOrder.DESC),
        icon: <IconArrowsTransferDown size={18} color={theme.colors.gray[7]} />,
      },
      {
        label: t('Changes in %s (low to high)', header.title),
        value: header.orderByChange,
        onSelect: (val) => setOrderBy(val?.value as string, QueryOrder.ASC),
        icon: <IconArrowsTransferUp rotate={'180'} size={18} color={theme.colors.gray[7]} />,
      },
    ];
  }, [header, setOrderBy, orderBy]);

  const SortIcon = () =>
    useMemo(() => {
      if (orderBy === header.orderBy) {
        return order === QueryOrder.DESC ? (
          <IconCaretDown size={18} color={theme.colors.gray[7]} />
        ) : (
          <IconCaretUp size={18} color={theme.colors.gray[7]} />
        );
      }
      return null;
    }, [orderBy, header]);

  const SortIconIndicator = () =>
    useMemo(() => {
      if (orderBy === header.orderByChange) {
        return order === QueryOrder.DESC ? (
          <IconArrowsTransferDown size={16} color={theme.colors.gray[7]} />
        ) : (
          <IconArrowsTransferUp size={16} color={theme.colors.gray[7]} />
        );
      }
      return null;
    }, [orderBy, header]);

  const HeaderValue = () =>
    useMemo(() => {
      return (
        <AccTooltip tooltip={header.orderBy ? `${t('Order by')} ${header.title}` : ''}>
          <AccTitle type="h5" pl={5} h="100%">
            {content}
          </AccTitle>
        </AccTooltip>
      );
    }, [content]);

  return (
    <Flex className={styles.headerCell} justify="end" align="center">
      {header.orderBy && header.orderByChange ? (
        <AccDropdownMenu width={285} items={dropdownItems} position="bottom" withinPortal>
          <Flex
            align="center"
            h="100%"
            className={cn(styles.headerCellContent, { [styles.clickable]: header.orderBy })}
          >
            <SortIcon />
            <SortIconIndicator />
            <HeaderValue />
          </Flex>
        </AccDropdownMenu>
      ) : (
        <Flex
          className={cn(styles.headerCellContent, { [styles.clickable]: header.orderBy })}
          align="center"
          h="100%"
          onClick={() => header.orderBy && setOrderBy(header.orderBy)}
        >
          <SortIcon />
          <HeaderValue />
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderColumn;
