import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  deselectAllDomains,
  deselectDomain,
  setSelectableDomains,
} from 'Actions/DomainsPageActions';
import { PaginationInput, useStoreDomainListQuery } from 'Ghql';
import { useSetOrOverwriteFilter } from 'Hooks/data/filter/setFilters';
import { useDeepEffect } from 'Hooks/useDeep';
import { useDomainsPageSelector } from 'Selectors/DomainsPageSelector';
import { ClientsFilter, FilterAttribute, FilterComparison, FilterValueType } from 'Types/Filter';
import { OrderingState } from 'Types/Sort';
import { folderSeparator, generateSelectId } from 'Utilities/generateSelectId';
import { DomainContextNode } from '../components/DomainCard/components/DomainContext';
import { defaultOrdering } from '../support/getSorting';
import { useDerivedState } from './useDerivedState';
import { useDomainSubscription } from './useDomainSubscription';
import { useDomainsQueryData } from './useDomainsQueryData';
import { useFiltersMemo } from './useFiltersMemo';
import { useGroupData } from './useGroupData';
import { useInitialGroup } from './useInitialGroup';
import { useSelectedDomains } from './useSelectedDomains';

export const useFetchDashboardCardsData = ({
  paginationPageSize,
}: {
  paginationPageSize: number;
}) => {
  const dispatch = useDispatch();
  const setFilter = useSetOrOverwriteFilter();
  const { selectedDomains: selectedCompareDomains } = useDomainsPageSelector();
  const [selectedDomains, setSelectedDomains] = useSelectedDomains();

  const initialGroup = useInitialGroup();

  // State declarations
  const [selectedGroup, setSelectedGroup] = useState<number | null>(initialGroup);
  const [pagination, setPagination] = useState<PaginationInput | null>(null);
  const [ordering, setOrdering] = useState<OrderingState | null>(null);

  const { data: { domainsList } = {} } = useStoreDomainListQuery();
  const totalDomains = domainsList?.length || 0;

  // Reset pagination when paginationPageSize changes
  useEffect(() => {
    if (!paginationPageSize) return;
    setPagination((prev) => ({
      startIndex: 0,
      stopIndex: paginationPageSize,
      page: 1,
      results: prev?.results || paginationPageSize,
    }));
  }, [paginationPageSize]);

  const { groupData, groupsLoading, groupDataError, refetchGroupData, groups } = useGroupData(
    pagination,
    totalDomains,
    selectedGroup,
    setPagination,
    setSelectedGroup,
  );

  const { numberOfKeywordsInGroup, totalKeywordsForSelectedDomains, domainsInGroup } =
    useDerivedState(groups, selectedGroup, selectedDomains);

  const currentGroupId = selectedGroup?.toString() || groups?.[0]?.clientId.toString();
  const numberOfDomainsInGroup = domainsInGroup.length || 0;

  const handleDeselectAllCompareDomains = useCallback(() => {
    dispatch(deselectAllDomains());
  }, [dispatch]);

  // Helper functions
  const updateSelectedDomains = useCallback(
    (domainId: string) => {
      // if we start selecting domains from scratch, clear the selected compare domains list and reset pagination (so we always start from the first page)
      if (selectedDomains.length === 0) {
        paginationPageSize &&
          setPagination((prev) => ({
            startIndex: 0,
            stopIndex: paginationPageSize,
            page: 1,
            results: prev?.results || paginationPageSize,
          }));
        if (selectedCompareDomains.length > 0) {
          handleDeselectAllCompareDomains();
        }
      }
      const selectedCompareDomainIds = selectedCompareDomains.map(
        (id) => id.split(folderSeparator)[1],
      );
      const isDeselecting = !!selectedDomains.find((id) => id === domainId);
      if (isDeselecting && selectedCompareDomainIds.includes(domainId)) {
        dispatch(deselectDomain(`${selectedGroup}${folderSeparator}${domainId}`));
      }
      const newDomainsList = isDeselecting
        ? selectedDomains.filter((id) => id !== domainId)
        : [...selectedDomains, domainId];
      setSelectedDomains(newDomainsList);
    },
    [
      selectedDomains,
      selectedCompareDomains,
      setSelectedDomains,
      paginationPageSize,
      handleDeselectAllCompareDomains,
      dispatch,
      selectedGroup,
    ],
  );

  const clearSelectedDomains = useCallback(() => {
    setSelectedDomains([]);
    paginationPageSize &&
      setPagination({
        page: 1,
        startIndex: 0,
        stopIndex: paginationPageSize,
        results: numberOfDomainsInGroup || paginationPageSize,
      });
  }, [numberOfDomainsInGroup, paginationPageSize, setSelectedDomains]);

  const handleSetGroup = useCallback(
    (groupId: number) => {
      clearSelectedDomains();
      handleDeselectAllCompareDomains();
      const clientsFilter: ClientsFilter = {
        attribute: FilterAttribute.CLIENTS,
        type: FilterValueType.LIST,
        comparison: FilterComparison.CONTAINS,
        value: [groupId.toString()],
      };
      setFilter(clientsFilter, 'no tracking');
      // Selected group must be set after updating the filter, as the domains query depends on the new filter.
      // Delaying the setSelectedGroup call to the next event loop iteration to ensure the filter is updated first.
      setTimeout(() => setSelectedGroup(groupId), 0);
    },
    [clearSelectedDomains, handleDeselectAllCompareDomains, setFilter],
  );

  const handleUpdateOrdering = useCallback(
    (newOrdering: OrderingState | null) => {
      if (!isEqual(ordering, newOrdering)) {
        setOrdering(newOrdering);
        paginationPageSize &&
          setPagination({
            page: 1,
            startIndex: 0,
            stopIndex: paginationPageSize,
            results: numberOfDomainsInGroup || paginationPageSize,
          });
      }
    },
    [ordering, numberOfDomainsInGroup, paginationPageSize],
  );

  const filters = useFiltersMemo(currentGroupId, selectedDomains);

  const { data, loading, error, refetch } = useDomainsQueryData(
    filters,
    ordering,
    pagination,
    groupsLoading,
    groupDataError,
    groupData,
    setPagination,
  );

  const refetchData = useCallback(() => {
    refetch();
    refetchGroupData();
  }, [refetch, refetchGroupData]);

  useDomainSubscription(refetchData);

  const dataWithSelectId: DomainContextNode[] = useMemo(() => {
    return (
      data?.tableDashboard?.domains?.map(({ id, clientId, ...clientRest }) => ({
        ...clientRest,
        clientId,
        id,
        selectId: generateSelectId('domain', id, clientId),
      })) || []
    );
  }, [data]);

  const includedDomains = useMemo(() => {
    return selectedDomains.length
      ? dataWithSelectId.filter((domain) => selectedDomains.includes(domain.id.toString()))
      : dataWithSelectId;
  }, [dataWithSelectId, selectedDomains]);

  // Side effects
  useDeepEffect(() => {
    const selectableIds = dataWithSelectId?.map((client) => client.selectId);
    dispatch(setSelectableDomains(selectableIds));
  }, [dispatch, dataWithSelectId]);

  return {
    data: includedDomains,
    loading: groupsLoading || loading,
    error,
    numberOfKeywordsInGroup,
    totalKeywordsForSelectedDomains,
    domainsInGroup: domainsInGroup.map((id) => id.toString()),
    clearSelectedDomains,
    selectedGroup: selectedGroup || Number(currentGroupId || 0),
    handleSetGroup,
    selectedDomains,
    pagination,
    setPagination,
    ordering: ordering || defaultOrdering,
    handleUpdateOrdering,
    updateSelectedDomains,
    handleDeselectAllCompareDomains,
  };
};
