import { ColumnSetting } from 'Components/DataTable';
import { Scalars } from 'Ghql';
import { t } from 'Utilities/i18n';

export enum DomainColumnIDs {
  CHECKBOX = 'checkbox',
  DOMAIN_DISPLAY_NAME = 'domain_display_name',
  CLIENT_NAME = 'client__name',
  KEYWORDS = 'keywords',
  ABOVE_THE_FOLD = 'above_the_fold',
  DISCOVERED_KEYWORDS = 'untracked_keywords',
  AVERAGE_RANK = 'avg_rank',
  AVERAGE_RANK_CHANGE = 'avg_rank_change',
  AVERAGE_CTR = 'avg_ctr',
  SHARE_OF_VOICE = 'share_of_voice',
  SHARE_OF_VOICE_CHANGE = 'share_of_voice_change',
  AI_SHARE_OF_VOICE = 'ai_share_of_voice',
  AI_SHARE_OF_VOICE_CHANGE = 'ai_share_of_voice_change',
  RANKING_DISTRIBUTION = 'ranking_distribution',
  DATE_ADDED = 'date_added',
  ACTIONS = 'actions',
}

export type DashboardNode = {
  __typename?: 'DashboardNode';
  avgRank?: Scalars['Float']['output'];
  avgRankCompare?: Scalars['Float']['output'];
  canUpdate?: Scalars['Boolean']['output'];
  client?: Scalars['String']['output'];
  clientId?: Scalars['String']['output'];
  count1To3?: Scalars['Int']['output'];
  count4To10?: Scalars['Int']['output'];
  count11To20?: Scalars['Int']['output'];
  count21To50?: Scalars['Int']['output'];
  count51To100?: Scalars['Int']['output'];
  countKeywords?: Scalars['Int']['output'];
  countNotifications?: Scalars['Int']['output'];
  countUnranked?: Scalars['Int']['output'];
  country?: Scalars['String']['output'];
  dateAdded?: Scalars['Date']['output'];
  displayName?: Scalars['String']['output'];
  domain?: Scalars['String']['output'];
  faviconUrl?: Scalars['String']['output'];
  id?: Scalars['Int']['output'];
  isDemoDomain?: Scalars['Boolean']['output'];
  organicTraffic?: Scalars['Int']['output'];
  organicTrafficCompare?: Scalars['Int']['output'];
  shareOfVoice?: Scalars['Int']['output'];
  shareOfVoiceCompare?: Scalars['Int']['output'];
  shareOfVoiceIsPercentage?: Scalars['Boolean']['output'];
  shareOfVoicePercentageValue?: Scalars['Float']['output'];
  shareOfVoicePercentageValueCompare?: Scalars['Float']['output'];
  untrackedKeywords?: Scalars['Int']['output'];
  aiShareOfVoice?: Scalars['Int']['output'];
  aiShareOfVoiceCompare?: Scalars['Int']['output'];
  selectId?: Scalars['String']['output'];
};

export const domainsColumnSettings = (
  accessRestricted?: boolean,
  shouldUseAiSoV?: boolean,
): ColumnSetting[] => [
  {
    id: DomainColumnIDs.CHECKBOX,
    queryWithClauses: [DomainColumnIDs.CHECKBOX],
    tableSetting: { required: true },
  },
  {
    id: DomainColumnIDs.DOMAIN_DISPLAY_NAME,
    queryWithClauses: [DomainColumnIDs.DOMAIN_DISPLAY_NAME],
    tableSetting: { required: true },
  },
  {
    id: DomainColumnIDs.CLIENT_NAME,
    queryWithClauses: [DomainColumnIDs.CLIENT_NAME],
    tableSetting: { getLabel: () => t('Group') },
  },
  {
    id: DomainColumnIDs.KEYWORDS,
    queryWithClauses: [DomainColumnIDs.KEYWORDS],
    tableSetting: { getLabel: () => t('Keywords') },
  },
  {
    id: DomainColumnIDs.DISCOVERED_KEYWORDS,
    queryWithClauses: [DomainColumnIDs.DISCOVERED_KEYWORDS],
    tableSetting: { getLabel: () => t('Discovered Keywords') },
  },
  {
    id: DomainColumnIDs.AVERAGE_RANK,
    queryWithClauses: [DomainColumnIDs.AVERAGE_RANK],
    tableSetting: { getLabel: () => t('Average Rank') },
  },
  {
    id: DomainColumnIDs.AVERAGE_RANK_CHANGE,
    queryWithClauses: [DomainColumnIDs.AVERAGE_RANK_CHANGE],
    tableSetting: {
      getLabel: () => t('Change'),
      grouping: DomainColumnIDs.AVERAGE_RANK,
      groupingOrder: 'after',
      defaultHide: false,
    },
  },
  {
    id: DomainColumnIDs.SHARE_OF_VOICE,
    queryWithClauses: [DomainColumnIDs.SHARE_OF_VOICE],
    tableSetting: {
      getLabel: () => t('Share of Voice'),
      defaultHide: typeof shouldUseAiSoV === 'boolean' ? shouldUseAiSoV : false,
    },
  },
  {
    id: DomainColumnIDs.SHARE_OF_VOICE_CHANGE,
    queryWithClauses: [DomainColumnIDs.SHARE_OF_VOICE_CHANGE],
    tableSetting: {
      getLabel: () => t('Change'),
      grouping: DomainColumnIDs.SHARE_OF_VOICE,
      groupingOrder: 'after',
      defaultHide: typeof shouldUseAiSoV === 'boolean' ? shouldUseAiSoV : false,
    },
  },
  {
    id: DomainColumnIDs.AI_SHARE_OF_VOICE,
    queryWithClauses: [DomainColumnIDs.AI_SHARE_OF_VOICE],
    tableSetting: {
      getLabel: () => t('AI Share of Voice'),
      defaultHide: typeof shouldUseAiSoV === 'boolean' ? !shouldUseAiSoV : false,
    },
  },
  {
    id: DomainColumnIDs.AI_SHARE_OF_VOICE_CHANGE,
    queryWithClauses: [DomainColumnIDs.AI_SHARE_OF_VOICE_CHANGE],
    tableSetting: {
      getLabel: () => t('Change'),
      grouping: DomainColumnIDs.AI_SHARE_OF_VOICE,
      groupingOrder: 'after',
      defaultHide: typeof shouldUseAiSoV === 'boolean' ? !shouldUseAiSoV : false,
    },
  },
  {
    id: DomainColumnIDs.RANKING_DISTRIBUTION,
    queryWithClauses: [DomainColumnIDs.RANKING_DISTRIBUTION],
    tableSetting: { getLabel: () => t('Ranking Distribution') },
  },
  {
    id: DomainColumnIDs.DATE_ADDED,
    queryWithClauses: [DomainColumnIDs.DATE_ADDED],
    tableSetting: { getLabel: () => t('Created On') },
  },
  {
    id: DomainColumnIDs.ACTIONS,
    queryWithClauses: [DomainColumnIDs.ACTIONS],
    tableSetting: { required: true, getLabel: () => t('Actions'), disabled: accessRestricted },
  },
];

export type DashboardDomainNode = {
  aboveTheFold: number | null;
  aiShareOfVoice: number | null;
  aiShareOfVoiceCompare: number | null;
  avgCtr: number | null;
  avgCtrCompare: number | null;
  avgRank: number | null;
  avgRankCompare: number | null;
  canUpdate: boolean;
  countDesktop: number | null;
  countKeywords: number | null;
  countMobile: number | null;
  country: string;
  dateAdded: string;
  displayName: string | null;
  domain: string;
  faviconUrl: string;
  id: number;
  isDemoDomain: boolean;
  shareOfVoice: number | null;
  shareOfVoiceCompare: number | null;
  shareOfVoiceIsPercentage: false;
  shareOfVoicePercentageValue: number | null;
  shareOfVoicePercentageValueCompare: number | null;
  untrackedKeywords: number | null;
};
