import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { AccContextToolbar } from 'Components/AccContextToolbar/AccContextToolbar';
import { useSelectedNodes } from '../../EditMode/support/state';
import GroupingBulkActions from './GroupingsBulkActions';

const GroupingsToolbar = observer(() => {
  const { selectedNodes, getSelectedNodes, setSelectedNodes } = useSelectedNodes();

  const deselectAll = useCallback(() => setSelectedNodes([]), [setSelectedNodes]);
  const totalSelected = selectedNodes.length;

  if (!totalSelected) return null;
  return (
    <AccContextToolbar
      enabled={!!totalSelected}
      onDisable={deselectAll}
      items={totalSelected}
      singularLabel={'item selected'}
      pluralLabel={'items selected'}
    >
      <GroupingBulkActions
        selectedNodes={selectedNodes}
        getSelectedNodes={getSelectedNodes}
        setSelectedNodes={setSelectedNodes}
        withCount={false}
        withDivider={true}
        darkTheme
      />
    </AccContextToolbar>
  );
});

export default GroupingsToolbar;
