import React from 'react';
import { Divider, Flex } from '@mantine/core';
import {
  IconCirclePlus,
  IconFileDownload,
  IconFolderCog,
  IconInfoCircle,
  IconTableOptions,
} from '@tabler/icons-react';
import { observer } from 'mobx-react';
import AccButton from 'Components/AccButton/AccButton';
import AccessControl from 'Components/AccessControl';
import { getDisabledDemoText } from 'Constants/messages';
import { useModal } from 'Hooks/base/useModal';
import { useQueryDomainInfo } from 'Hooks/data/domain/useQueryDomainInfo';
import ActionbarContainer from 'Pages/Layout/ActionsMenu/components/ActionbarContainer';
import { TableIDs } from 'Types/Table';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t } from 'Utilities/i18n';
import { useSelectedNodes } from '../../EditMode/support/state';
import GroupingBulkActions from './GroupingsBulkActions';
import style from '../../groupings.module.scss';

const GroupingViewModeSelectActions = observer(() => {
  const { selectedNodes, getSelectedNodes, setSelectedNodes } = useSelectedNodes();
  if (!selectedNodes.length) return null;
  return (
    <>
      <Divider h={26} m="auto" orientation="vertical" color="gray.2" className={style.seperator} />
      <GroupingBulkActions
        selectedNodes={selectedNodes}
        getSelectedNodes={getSelectedNodes}
        setSelectedNodes={setSelectedNodes}
        withCount={true}
      />
      <AccButton variant="tertiary" ml="auto" onClick={() => setSelectedNodes([])}>
        {t('Clear selection')}
      </AccButton>
    </>
  );
});

type GroupingViewModeTableActionsProps = {
  setEditMode: (val: boolean) => void;
  loading: boolean;
};

const GroupingViewModeTableActions = React.memo(
  ({ loading, setEditMode }: GroupingViewModeTableActionsProps) => {
    const { isDemoDomain } = useQueryDomainInfo();
    const { showModal } = useModal();
    const handleShowSelectTagModal = () => {
      showModal({
        modalType: 'SelectTagType',
        modalTheme: 'light',
      });
    };
    const showReportModal = () => {
      showModal({
        modalType: 'FoldersReport',
        modalTheme: 'light',
        modalProps: {},
        nested: true,
      });
    };

    return (
      <Flex align="center" wrap="wrap" gap="sm">
        <AccessControl>
          <AccButton
            onClick={handleShowSelectTagModal}
            disabled={loading || isDemoDomain}
            tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
            loading={loading}
            variant="primary"
            leftSection={<IconCirclePlus size={18} />}
            data-testid="action-create-tag"
          >
            {t('Create tag')}
          </AccButton>
          <AccButton
            onClick={() => setEditMode(true)}
            leftSection={<IconFolderCog size={20} />}
            disabled={loading || isDemoDomain}
            tooltip={isDemoDomain ? getDisabledDemoText() : undefined}
            loading={loading}
            variant="secondary"
            trackingKey={TrackingKey.EditFolders}
          >
            {t('Edit folders')}
          </AccButton>
        </AccessControl>
        <AccButton
          onClick={showReportModal}
          variant="tertiary"
          leftSection={<IconFileDownload size={20} />}
          trackingKey={TrackingKey.DownloadReport}
        >
          {t('Download report')}
        </AccButton>
        <AccButton
          variant="tertiary"
          leftSection={<IconInfoCircle size={20} />}
          trackingKey={TrackingKey.HelpGuide}
          onClick={() =>
            window.open(
              'https://www.accuranker.com/help/keywords/tag-cloud/',
              '_blank',
              'noopener,noreferrer',
            )
          }
        >
          {t('Help guide')}
        </AccButton>
        <GroupingViewModeSelectActions />
      </Flex>
    );
  },
);

type GroupingViewModeActionsProps = {
  loading: boolean;
  setEditMode: (val: boolean) => void;
};

export const GroupingViewModeActions = React.memo(
  ({ loading, setEditMode }: GroupingViewModeActionsProps) => {
    const { showModal } = useModal();
    const showTableSettingsModal = () => {
      showModal({
        modalType: 'TableSettingsModal',
        modalTheme: 'light',
        modalProps: { tableName: TableIDs.FOLDERS },
      });
    };
    return (
      <ActionbarContainer>
        <GroupingViewModeTableActions loading={loading} setEditMode={setEditMode} />
        <ActionbarContainer.Sidebar>
          <AccButton
            onClick={showTableSettingsModal}
            variant="tertiary"
            leftSection={<IconTableOptions size={20} />}
            trackingKey={TrackingKey.TableSettings}
          >
            {t('Configure columns')}
          </AccButton>
        </ActionbarContainer.Sidebar>
      </ActionbarContainer>
    );
  },
  propsIsEqualComparison,
);
GroupingViewModeActions.displayName = 'GroupingViewModeActions';
GroupingViewModeTableActions.displayName = 'GroupingViewModeTableActions';
