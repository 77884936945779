import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Center } from '@mantine/core';
import {
  IconBaselineDensityLarge,
  IconBaselineDensityMedium,
  IconBaselineDensitySmall,
  IconLayoutGrid,
} from '@tabler/icons-react';
import { setTableViewMode } from 'Actions/TableAction';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { TableSize, useTableStore } from 'Components/DataTable';
import { useActions } from 'Hooks/redux/useActions';
import { StoreType } from 'Types/Store';
import { TableID, TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';

type Props = {
  tableName: TableID;
  simple?: boolean;
  onChange?: (value: TableSize) => void;
};

const useControlData = (tableName: string, simple?: boolean) => {
  const defaultView = {
    label: (
      <AccTooltip delay={0} tooltip={t('Set table view density to default')}>
        <Center>
          <IconBaselineDensityLarge size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.DEFAULT,
  };
  const mediumView = {
    label: (
      <AccTooltip delay={0} tooltip={t('Set table view density to medium')}>
        <Center>
          <IconBaselineDensityMedium size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.COMFORTABLE,
  };
  const compactView = {
    label: (
      <AccTooltip delay={0} tooltip={t('Set table view density to compact')}>
        <Center>
          <IconBaselineDensitySmall size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.COMPACT,
  };

  // export const treeView = {
  //   label: (
  //     <AccTooltip delay={0} tooltip={t('Select tree view')}>
  //       <Center>
  //         <IconListTree size={14} />
  //       </Center>
  //     </AccTooltip>
  //   ),
  //   value: TableSize.TREE,
  // };
  const cardView = {
    label: (
      <AccTooltip delay={0} tooltip={t('Select card view')}>
        <Center>
          <IconLayoutGrid size={14} />
        </Center>
      </AccTooltip>
    ),
    value: TableSize.CARD,
  };

  const segmentedControlDataKeyword = [defaultView, mediumView, compactView];
  const segmentedControlDataRestViews = [defaultView, compactView];
  const segmentedControlDataDomains = [defaultView, compactView, cardView];
  switch (true) {
    case simple:
      return segmentedControlDataRestViews;
    case tableName === TableIDs.KEYWORDS:
      return segmentedControlDataKeyword;
    case tableName === TableIDs.DOMAINS:
      return segmentedControlDataDomains;
    default:
      return segmentedControlDataRestViews;
  }
};

export const ViewMode = ({ tableName, simple, onChange }: Props) => {
  const tableStore = useTableStore(tableName);
  const [, forceUpdate] = useState(0);
  const viewMode = useSelector(
    (store: StoreType) =>
      store.table?.[tableName]?.viewMode ?? store.table?.viewMode ?? TableSize.DEFAULT,
  );

  const segmentedControlData = useControlData(tableName, simple);

  const { setTableViewMode: updateTableViewMode } = useActions({
    setTableViewMode,
  });

  const onSave = (value) => {
    updateTableViewMode(tableName, value);
    tableStore?.setTableViewMode(value);
    onChange?.(value);
  };

  useEffect(() => {
    //we need to force a rerender when the data is changed going from paused domains to active domains.
    //Otherwise the selected state of the card icon will not render correctly.
    forceUpdate((prev) => prev + 1);
  }, [segmentedControlData.length]);

  return (
    <AccSegmentedControl
      key={viewMode}
      color={'snorlax'}
      data={segmentedControlData}
      onChange={onSave}
      defaultValue={viewMode}
    />
  );
};
