import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuProps, ScrollArea } from '@mantine/core';
import FilterCount from 'Components/Filters/Common/FilterCount';
import AccText from 'Components/Text/AccText';
import styles from './dropdown.module.scss';

export type DropdownItem = {
  label?: string;
  disabled?: boolean;
  value?: string | object;
  icon?: React.ReactNode;
  link?: string;
  onSelect?: (val?: DropdownItem) => void;
  divider?: boolean;
  tooltip?: string;
  count?: number;
};

type AccDropdownMenuProps = {
  children: JSX.Element;
  items: DropdownItem[];
  position?: MenuProps['position'];
  maxVisibleCount?: number;
  onSelect?: (val?: DropdownItem) => void;
  width?: number | 'target';
  closeOnItemClick?: boolean;
  bodyStyle?: CSSProperties;
  itemClassName?: string;
  withinPortal?: boolean;
  offset?: number;
  disabled?: boolean;
  label?: string;
  onChange?: () => void;
  darkTheme?: boolean;
};

/**
 * Combine a list of secondary actions into single
 */
export const AccDropdownMenu = ({
  children,
  items,
  onSelect,
  position,
  maxVisibleCount = 10,
  width,
  closeOnItemClick,
  bodyStyle,
  itemClassName,
  offset,
  disabled,
  label,
  onChange,
  //set default to false as rendering this menu within another portal will cause issues with use-click-outside hook
  withinPortal = false,
  darkTheme = false,
}: AccDropdownMenuProps) => {
  const showOverflow = items.length > maxVisibleCount;
  const Wrapper = showOverflow ? ScrollArea : React.Fragment;
  const wrapperProps = showOverflow ? { style: { height: 350 } } : {};
  return (
    <Menu
      withinPortal={withinPortal}
      shadow="md"
      width={width ?? 200}
      position={position}
      closeOnItemClick={closeOnItemClick}
      classNames={{ item: styles.menuItem }}
      transitionProps={{ duration: 0 }}
      offset={offset}
      disabled={disabled}
      onChange={onChange}
    >
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown
        className={styles.dropdown}
        data-dark-theme={darkTheme || undefined}
        miw={200}
        style={bodyStyle}
      >
        {label ? (
          <>
            <Menu.Label>
              <AccText overflowEllipsis variant="label" c={`${darkTheme ? 'white' : 'gray.9'}`}>
                {label}
              </AccText>
            </Menu.Label>
            <Menu.Divider />
          </>
        ) : null}
        <Wrapper {...wrapperProps}>
          {items.reduce((acc, item) => {
            if (item.divider) {
              acc.push(<Menu.Divider key={item.label?.concat('_divider')} />);
            }
            const Component: React.ElementType = item.link ? Link : 'button';
            let handleOnClick = item.onSelect ? () => item.onSelect?.(item) : undefined;

            if (onSelect) {
              handleOnClick = () => onSelect(item);
            }
            acc.push(
              <Menu.Item
                title={item.tooltip}
                leftSection={item.icon}
                key={item.label}
                component={Component as any}
                to={item.link}
                onClick={handleOnClick}
                className={itemClassName}
                disabled={item.disabled}
                data-dark-theme={darkTheme || undefined}
              >
                {item.label}
                <FilterCount count={item.count} />
              </Menu.Item>,
            );
            return acc;
          }, [] as JSX.Element[])}
        </Wrapper>
      </Menu.Dropdown>
    </Menu>
  );
};
