import { useMemo } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import Highcharts, {
  AxisTickPositionsArray,
  Options,
  PlotOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import moment from 'moment';
import type {
  LineChartTypes,
  SeriesItem,
  UseChartConfigProps,
} from 'Components/Chart/LineChart/support/types';
import { ChartButtonTheme } from 'Components/Chart/support/buttonStyleConfig';
import {
  DEFAULT_CHART_CONFIG,
  DEFAULT_NAVIGATOR,
  ENABLED_RANGE_SELECTOR,
  TOOLTIP_CHART_FORMATTING,
} from 'Components/Chart/support/constants';
import { LandingTableIDs } from 'Pages/Keywords/LandingPage/components/KeywordLandingTable/support/constants';
import {
  MultiSeriesStockTooltipContent,
  MultiSeriesStockTooltipContentProps,
  StockTooltipContent,
  StockTooltipContentProps,
} from 'Pages/Keywords/Overview/components/StockTooltipContent/StockTooltipContent';
import { getPointDate } from 'Pages/Keywords/Overview/components/StockTooltipContent/helpers';
import { PrettifyUnion } from 'Types/Prettify';
import { t } from 'Utilities/i18n';
import { devError } from 'Utilities/log';
import { mantineTheme } from 'css/mantine/theme';
import { CHART_HEIGHT, LINE_TOOLTIP_HEADER_FORMAT, lineChartColors } from '../constants';
import {
  chartPointFormatter,
  formatChartSeries,
  getChartPositionerTooltip,
  getChartType,
  getFakeSeriesData,
  getLegendLabelFormat,
  rankLabelFormatter,
  useLabelFormatter,
} from '../helpers';
import { chartTooltipTypes } from '../types';
import processExtremes from './processExtremes';

type TooltipFormaterProps =
  | PrettifyUnion<{ type: chartTooltipTypes.STOCK } & StockTooltipContentProps>
  | PrettifyUnion<{ type: chartTooltipTypes.MULTI } & MultiSeriesStockTooltipContentProps>;

const tooltipFormatter = (props: TooltipFormaterProps) => {
  const htmlLabel = document.createElement('div');
  const root = createRoot(htmlLabel);
  if (props.type === chartTooltipTypes.STOCK) {
    flushSync(() => {
      root.render(
        <MantineProvider theme={mantineTheme} defaultColorScheme="light">
          <StockTooltipContent {...props} />
        </MantineProvider>,
      );
    });
    return htmlLabel.innerHTML;
  }

  flushSync(() => {
    root.render(
      <MantineProvider theme={mantineTheme} defaultColorScheme="light">
        <MultiSeriesStockTooltipContent {...props} />
      </MantineProvider>,
    );
  });
  return htmlLabel.innerHTML;
};

const getControlLineColor = (series?: SeriesItem[], colors?: Record<string, string>): string => {
  const primarySeries = series?.find((e) => e.primary);

  let result;

  if (primarySeries) {
    result = colors?.[primarySeries?.name];
  } else {
    result = colors?.[series?.[0]?.name ?? ''];
  }
  return result || lineChartColors.line.DEFAULT_COLOR;
};

const getDefaultDateRangeSelector = (data?: { x: number }[]): number => {
  const min = data?.[0]?.x;
  const max = data?.[data.length - 1]?.x;

  if (!data || !min || !max) return 2;

  const firstMoment = moment(min);
  const lastMoment = moment(max);
  const diff = lastMoment.diff(firstMoment, 'days');
  switch (true) {
    // if less than 30 days then show 1 week as default range
    case diff < 30:
      return 0;
    // if less than 90 days then show 1 month as default range
    case diff < 90:
      return 1;
    // for other case show 3 months as default
    default:
      return 2;
  }
};

// used to resolve issue with wrong range selected for navigator deploy
export const getNavigatorXAxis = (seriesItems) => {
  const data = seriesItems?.[0]?.data;
  return {
    min: data?.[0]?.x || data?.[0]?.[0],
    max: data?.[data.length - 1]?.x || data?.[data.length - 1]?.[0],
    labels: { y: 10 },
  };
};

export const getRangeSelectorButtonConfig = () => ({
  buttons: [
    {
      type: 'week',
      count: 1,
      text: t('1w'),
      title: t('View 1 week'),
    },
    {
      type: 'month',
      count: 1,
      text: t('1m'),
      title: t('View 1 month'),
    },
    {
      type: 'month',
      count: 3,
      text: t('3m'),
      title: t('View 3 months'),
    },
    {
      type: 'ytd',
      text: t('YTD'),
      title: t('View year to date'),
    },
    {
      type: 'year',
      count: 1,
      text: t('1y'),
      title: t('View 1 year'),
    },
    {
      type: 'all',
      text: t('All '), // Space on purpose to trigger another translation, as "All" is already translated
      title: t('View all'),
    },
  ],
});

export const useChartConfig = (
  config: UseChartConfigProps & { chartType: LineChartTypes; showHoursOnXAxis?: boolean },
  metric?: string,
  yAxisLabel?: string,
): Options => {
  if (metric === LandingTableIDs.DYNAMIC_CTR) {
    config.showPercentage = true;
  }

  const labelsFormatter = useLabelFormatter(metric, config.showPercentage);

  if (!config.intl) {
    devError(
      'Make sure to supply the "intl" prop to the "useChartConfig" hook to ensure that the chart functions correctly.',
    );
  }

  const lineColor = getControlLineColor(config.series, config.colors);
  const series = useMemo(
    // eslint-disable-next-line no-confusing-arrow
    () =>
      config.showFake
        ? formatChartSeries(
            config.isRank ?? false,
            config.chartType,
            getFakeSeriesData(),
            config.colors,
          )
        : formatChartSeries(
            config.isRank ?? false,
            config.chartType,
            config.series,
            config.colors,
            config.visibility,
          ),
    [config.showFake, config.series, config.colors, JSON.stringify(config.visibility || {})],
  );

  const chartConfig: Options['chart'] = {
    type: getChartType(config.chartType),
    height: config.height
      ? config.height
      : config.placeLegendBelowChart
      ? CHART_HEIGHT + 100
      : CHART_HEIGHT,
    marginRight: config.disableLegend || config.placeLegendBelowChart ? undefined : 280,
    colorCount: 13,
    marginTop: 10,
    marginBottom: config.sparklineMode ? 10 : 41,
    animation: false,
    zooming: {
      type: 'xy',
      resetButton: {
        position: {
          // align: 'right', // by default
          // verticalAlign: 'top', // by default
          x: 0,
          y: -10,
        },
        //style the button similar to AccButton secondary
        theme: ChartButtonTheme,
      },
    },
  };

  const xAxis: XAxisOptions = {
    type: 'datetime',
    minPadding: 0,
    maxPadding: 0,
    offset: 0,
    ordinal: false,
    allowDecimals: false,
    crosshair: false,
    minTickInterval: config.showHoursOnXAxis ? 3600 * 1000 : 24 * 3600 * 1000,
    tickWidth: 0,
    lineColor: lineChartColors.line.AXIS_COLOR,
    lineWidth: config.sparklineMode ? 0 : 1,
    labels: {
      enabled: !config.sparklineMode,
      overflow: 'justify',
      y: 10,
      style: {
        whiteSpace: 'nowrap',
      },
    },
    events: {
      afterSetExtremes() {
        const currentChart = this.chart;
        const { dataMin, dataMax } = currentChart.yAxis[0].getExtremes();
        const { min: newMin, max: newMax } = processExtremes(
          dataMin,
          dataMax,
          config.isRank ? 'rank' : 'default',
        );
        currentChart.yAxis[0].setExtremes(newMin, newMax);
      },
    },
  };

  const getYAxisTitle = (): Highcharts.YAxisTitleOptions => {
    if (!yAxisLabel) {
      return { text: null };
    }
    return { text: yAxisLabel, style: { fontWeight: 'bold' } };
  };

  const yAxis: YAxisOptions[] = [
    {
      title: getYAxisTitle(),
      top: '0%',
      height: '100%',
      opposite: false,
      gridLineWidth: config.sparklineMode ? 0 : 1,
      ...(config.isRank
        ? {
            tickPositioner() {
              // Make sure yTicks do not go futher up than 1 for rank
              if (!this.tickPositions) {
                return this.tickPositions as unknown as AxisTickPositionsArray;
              }
              const positions = this.tickPositions.filter((x) => x >= 0);
              if (positions[0] === 0) {
                positions[0] = 1;
              }
              return positions as AxisTickPositionsArray;
            },
            reversed: true,
          }
        : {
            showLastLabel: !config.yAxisReversed,
            reversed: !!config.yAxisReversed,
          }),
      labels: {
        enabled: !config.sparklineMode,
        formatter() {
          return config.isRank ? rankLabelFormatter(this) : labelsFormatter(this);
        },
        align: 'right',
        y: 3,
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
      },
    },
  ];

  const legendBottomPlacementOptions: Options['legend'] = {
    align: 'left',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    x: 30,
    width: 600,
    maxHeight: 100,
    padding: 10,
  };

  const legendDefaultPlacementOptions: Options['legend'] = {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: -20,
    width: 200,
    maxHeight: 220,
  };

  const legend: Options['legend'] = {
    ...(config.placeLegendBelowChart
      ? legendBottomPlacementOptions
      : legendDefaultPlacementOptions),
    y: config.hasControl ? 50 : 0,
    useHTML: true,
    enabled: !config.disableLegend,
    labelFormatter() {
      return getLegendLabelFormat(this);
    },
  };

  const exporting = {
    buttons: {
      contextButton: {
        x: -220,
      },
    },
  };

  const tooltip: Options['tooltip'] = {
    ...TOOLTIP_CHART_FORMATTING,
    shared: true,
    split: false,
    positioner(labelWidth, labelHeight, point) {
      return getChartPositionerTooltip(labelWidth, labelHeight, point, this);
    },
    headerFormat: LINE_TOOLTIP_HEADER_FORMAT,
    formatter: config.tooltip?.type
      ? function () {
          if (config.tooltip?.type === chartTooltipTypes.STOCK) {
            return tooltipFormatter({
              type: config.tooltip?.type,
              intl: config.intl,
              isRank: config.isRank || false,
              title: config.tooltip?.title,
              point: this,
              showPercentage: config.tooltip.showPercentage || false,
              customNumFormatter: config.tooltip.customNumFormatter,
              bottomText: config.tooltip.bottomText,
              customText: config.tooltip.customText && config.tooltip.customText(this),
              dontShowTotalKeywords: config.tooltip.dontShowTotalKeywords,
            });
          }
          if (config.tooltip?.type === chartTooltipTypes.MULTI) {
            return tooltipFormatter({
              type: config.tooltip?.type,
              intl: config.intl,
              isRank: config.isRank || false,
              point: this,
              showPercentage: config.tooltip.showPercentage || false,
              customNumFormatter: config.tooltip.customNumFormatter,
              bottomRightText: config.tooltip.bottomRightText,
              includeTimeStamp: config.tooltip.includeTimeStamp,
            });
          }
        }
      : function () {
          let html = '';
          const date = getPointDate(this);

          if (date) {
            html += `<div class="chart-tooltip-table-tr"><div class="chart-tooltip-table-th">${date}</div></div>`;
          }

          this?.points?.forEach((point) => {
            html += chartPointFormatter(
              config.intl,
              point,
              config.showPercentage,
              metric,
              config.isRank,
            );
          });

          return html;
        },

    footerFormat: '</div>',
    valueDecimals: 0,
    xDateFormat: '%b %e, %Y',
    hideDelay: 5,
  };

  const plotOptions: PlotOptions = {
    area: {
      connectNulls: false,
    },
    series: {
      stickyTracking: true,
      animation: false,
      cursor: 'pointer',
      lineWidth: 3,
      connectNulls: false,
      states: { inactive: { enabled: false } },
      turboThreshold: 500000,
      boostThreshold: 1,
      threshold: config.isRank ? Infinity : -Infinity,
    },
  };

  const navigator: Options['navigator'] = {
    ...DEFAULT_NAVIGATOR,
    yAxis: {
      reversed: config.isRank,
    },
    xAxis: {
      ...getNavigatorXAxis(series),
      lineWidth: 1,
      lineColor: lineChartColors.line.AXIS_COLOR,
    },
    margin: config.enableRangeSelector ? 50 : 25,
    series: config.multiLineNavigator
      ? series
      : {
          color: lineChartColors.navigator.BORDER_COLOR,
          lineColor,
          turboThreshold: 99999,
          threshold: config.isRank ? Infinity : -Infinity,
          fillOpacity: config.chartType === 'area' ? undefined : 0,
        },
  };

  const boost: Options['boost'] = {
    useGPUTranslations: true,
    seriesThreshold: 1,
  };

  const overrideOptions = merge(
    config.enableRangeSelector
      ? {
          rangeSelector: {
            ...ENABLED_RANGE_SELECTOR,
            ...getRangeSelectorButtonConfig(),
            inputEnabled: true,
            selected: getDefaultDateRangeSelector(series[0]?.data),
          },
        }
      : {},
    config.extraOptions || {},
  );

  // cloneDeep is needed with merge to prevent mutation other options used by other charts.
  return useMemo<Options>(
    () =>
      merge(
        cloneDeep({
          chart: chartConfig,
          xAxis,
          yAxis,
          legend,
          series: series as Highcharts.SeriesOptionsType[],
          boost,
          exporting,
          tooltip,
          navigator,
          plotOptions,
          ...DEFAULT_CHART_CONFIG,
        }),
        overrideOptions,
      ),
    [series, config.showPercentage, tooltip],
  );
};
