import { ApolloQueryResult } from '@apollo/client';
import { Flex } from '@mantine/core';
import { IconCaretDownFilled, IconSearch, IconSitemap, IconSquareX } from '@tabler/icons-react';
import AccButton from 'Components/AccButton/AccButton';
import GroupsAndDomainsDropdown, {
  GroupItem,
  GroupsDomainItem,
} from 'Components/Controls/Dropdowns/GroupsAndDomainsDropdown';
import { GroupsListQuery } from 'Ghql';
import { useDeepEffect } from 'Hooks/useDeep';
import useUserPermission from 'Hooks/useUserPermission';
import { onAddGroupCallback } from 'Pages/Domains/support/onAddGroupCallback';
import { useGroupsNavigationActions } from 'Pages/Layout/DashboardTemplate/Menu/GroupsAndDomains/support/hooks';
import { TrackingKey } from 'Utilities/Analytics/mixpanel';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { useDropdownData } from '../support/useDropdownData';
import styles from './cardViewGroupsAndDomains.module.scss';

const ClearSelectionButton = ({
  disabled = false,
  onClick,
}: {
  disabled?: boolean;
  onClick: () => void;
}) => (
  <AccButton
    variant="tertiary"
    disabled={disabled}
    leftSection={<IconSquareX size={18} color="currentColor" />}
    onClick={() => onClick()}
  >
    {t('Clear selection')}
  </AccButton>
);

export const CardViewGroupsAndDomains = ({
  selectedGroup,
  handleSetGroup,
  selectedDomains,
  updateSelectedDomains,
  clearSelectedDomains,
}: {
  selectedGroup: number | null;
  handleSetGroup: (groupId: number) => void;
  selectedDomains: string[];
  updateSelectedDomains: (domainId: string) => void;
  clearSelectedDomains: () => void;
}) => {
  const {
    groups,
    selectedDomainItem,
    groupDataLoading,
    domainDataLoading,
    domainIds,
    domains,
    refetch,
    refetchDomains,
  } = useDropdownData({ selectedGroup });
  const selectedGroupItem = groups.find((groupItem) => groupItem.id === selectedGroup);

  const { userHasWriteAccess, isAdmin, isSuperuser } = useUserPermission();

  useDeepEffect(() => {
    // if selected group is not in the list and data is not loading, set the first group as selected
    if (!groupDataLoading && !selectedGroupItem) {
      handleSetGroup(groups[0].id);
    }
  }, [selectedGroupItem, groups, groupDataLoading]);

  const onAddGroup = (id: string, result: ApolloQueryResult<GroupsListQuery>, tries: number) => {
    onAddGroupCallback({
      id,
      result,
      tries,
      refetch,
      handleSetGroup,
    });
  };

  const actions = useGroupsNavigationActions(domainIds, refetch, {
    onAdd: onAddGroup,
    afterGroupDelete: (groupId) => {
      // if deleted group is active group, select first available group
      if (selectedGroupItem?.id && groupId === selectedGroupItem.id) {
        const firstGroup = groups.find((group) => group.id !== groupId);
        firstGroup && handleSetGroup(firstGroup.id);
        return;
      }
    },
    onEditDomain: refetchDomains,
  });

  const groupsDropdownTitle = selectedGroupItem?.displayName;

  return (
    <Flex gap="md" align="center">
      <GroupsAndDomainsDropdown
        item={selectedGroupItem}
        loading={groupDataLoading}
        trackingKey={TrackingKey.OpenCardviewSelectGroupPopover}
        items={groups}
        leftSection={<IconSitemap size={32} className={styles.sitemap} stroke={1.5} />}
        rightSection={<IconCaretDownFilled size={24} className={styles.caret} />}
        dropdownAddButtonLabel={t('Add new group')}
        dropdownHeader={t('Groups')}
        dropdownSearchPlaceholder={t('Search groups')}
        targetPlaceholder={groupsDropdownTitle || ''}
        classNames={{ title: styles.title, button: styles.button }}
        showAddButton={isAdmin || isSuperuser}
        onSelect={(groupItem: GroupItem) => {
          handleSetGroup(groupItem.id);
        }}
        onAdd={actions.onAddGroup}
        onEdit={actions.onEditGroup}
        onDelete={actions.onDeleteGroup}
      />
      <GroupsAndDomainsDropdown
        trackingKey={TrackingKey.OpenCardviewSelectDomainPopover}
        showOptionCheckboxes
        closeOnSelect={false}
        loading={domainDataLoading}
        item={selectedDomainItem}
        items={domains?.filter(notEmpty) ?? []}
        leftSection={<IconSearch size={18} className={styles.search} />}
        rightSection={<></>}
        selectedDomains={selectedDomains}
        dropdownAddButtonLabel={t('Add new domain')}
        dropdownHeader={t('Domains')}
        dropdownSearchPlaceholder={t('Search for any domain')}
        targetPlaceholder={t('Search and select domains')}
        showAddButton={userHasWriteAccess}
        onAdd={actions.onDomainsAdd(selectedGroupItem?.id)}
        onEdit={actions.onDomainsEdit}
        onDelete={actions.onDomainsDelete}
        onSelect={(domain: GroupsDomainItem) => updateSelectedDomains(domain.id)}
        clearAllSlot={
          <ClearSelectionButton onClick={clearSelectedDomains} disabled={!selectedDomains.length} />
        }
      />

      {selectedDomains?.length > 0 && <ClearSelectionButton onClick={clearSelectedDomains} />}
    </Flex>
  );
};
