/* eslint-disable import/no-unused-modules */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NavigatorOptions, Options, SeriesZonesOptionsObject } from 'highcharts';
import { InjectedIntl } from 'Types/Intl';

export interface SeriesDataItem {
  x: number;
  y: number | null;
  totalCount?: number;
  totalOwnedCount?: number;
}

export interface SeriesItem {
  /** name should be unique - for chart logics, in case its not we need to introduce id for it */
  name: string;
  primary?: boolean;
  color?: string;
  visible?: boolean;
  data: SeriesDataItem[];
  zoneAxis?: string;
  zones?: SeriesZonesOptionsObject[];
}

export type LineColorsType = {
  [key: string]: string;
};

export interface NotesType {
  createdAt: string;
  [key: string]: any;
}

export enum LineChartType {
  LINE = 'line',
  AREA = 'area',
}

export type ChartConfigOverride = {
  navigatorSeries?: NavigatorOptions['series'];
  noData?: string;
  fillColor?: string;
};

export type LineChartTypes = LineChartType | LineChartType[keyof LineChartType];

export enum chartTooltipTypes {
  STOCK = 'stock',
  MULTI = 'multi',
}

export type TooltipConfiguration =
  | {
      type: chartTooltipTypes.STOCK;
      showPercentage?: boolean;
      title: string;
      customNumFormatter?: (num: number) => string;
      bottomText?: string;
      customText?: (ctx: Highcharts.TooltipFormatterContextObject) => string;
      dontShowTotalKeywords?: boolean;
    }
  | {
      type: chartTooltipTypes.MULTI;
      showPercentage?: boolean;
      customNumFormatter?: (value: number) => string;
      bottomRightText?: string;
      includeTimeStamp?: boolean;
    };

export interface UseChartConfigProps {
  /** Tooltip configuration */
  tooltip?: TooltipConfiguration;
  /** Chart type default: 'line' */
  chartType?: LineChartTypes;
  /** overrides parts of chart config **/
  configOverride?: ChartConfigOverride;
  extraOptions?: Options;
  /** Data used to display in chart */
  series?: SeriesItem[];
  /** used to add padding to legend so controls to prevent controls overflow it */
  hasControl?: boolean;
  /** format numbers as percentage */
  showPercentage?: boolean;
  /** User Notes for chart */
  notes?: NotesType[];
  /** used to show fake data as placeholder before loading */
  showFake?: boolean;
  /** colors for elements */
  colors?: { [key: string]: string };
  /** hide chart lines while loading */
  isLoading?: boolean;
  /** set step to 1 and reverse y-axis, from 1 to 100 */
  isRank?: boolean;
  /** reverse y-axis */
  yAxisReversed?: boolean;
  /** hide legend, used to provide custom legend */
  disableLegend?: boolean;
  /** Used to place the legend below the chart */
  placeLegendBelowChart?: boolean;
  /** used to control visibility outside chart, of empty will be handled automatically */
  visibility?: Record<string, boolean>;
  /** used to enable range selector */
  enableRangeSelector?: boolean;
  /** overwrite default chart height */
  height?: number;
  /**
   * show several lines in navigator, used by tagscloud
   */
  multiLineNavigator?: boolean;
  /**
   * -----
  /** @private: will be passed automatically, no need to pass it manually*/
  intl: InjectedIntl;
  /** SparklineMode will render the chart without x- and y-labels or gridlines  */
  sparklineMode?: boolean;
}
