import { ComponentType, PropsWithChildren, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDomainInfoQuery } from 'Ghql';
import { DomainInfo, DomainTypeChoices } from 'Query';
import { selectDomainId, selectDomainList, selectLanguage } from 'Selectors/DomainSelector';
import { getDomainName } from 'Utilities/Common/domain';
import colorScheme from 'Utilities/colors';

type UseQueryDomainInfo = {
  domainInfo?: DomainInfo;
  loadingDomainInfo: boolean;
  domainId?: string | null;
  asPercentage?: boolean;
  colorMap?: Record<string, string>;
  displayNameMap?: Record<string, string>;
  refetch: () => void;
  isDemoDomain?: boolean;
  isNaverDomain?: boolean;
};

export const useIsPercentage = () => {
  const domainId = useSelector(selectDomainId);
  const variables = useMemo(() => ({ id: domainId || '' }), [domainId]);
  const { data } = useDomainInfoQuery({
    variables,
    skip: !domainId,
  });
  return data?.domain?.shareOfVoicePercentage ?? false;
};

export const useDomainId = (): string | null => {
  return useSelector(selectDomainId);
};

export const useIsGroupDomains = () => {
  const domainId = useDomainId();
  return !domainId;
};
export const useDomainIds = () => {
  return useSelector(selectDomainList);
};

const getDomainColors = (domainInfo, color) => {
  const result: Record<string, string> = {};
  if (domainInfo?.youtubeChannelName) result[domainInfo.youtubeChannelName] = color;
  if (domainInfo?.displayName) result[domainInfo.displayName] = color;
  if (domainInfo?.domain) result[domainInfo.domain] = color;
  return result;
};

export const useQueryDomainInfo = () => {
  const domainId = useDomainId();

  const { data, loading, refetch } = useDomainInfoQuery({
    variables: { id: domainId || '' },
    fetchPolicy: 'cache-first',
    skip: !domainId,
  });

  // safe refetch, will not refetch if domainId is not defined
  const safeRefetch = useCallback(() => {
    if (domainId) refetch();
  }, [refetch, domainId]);

  const domainInfo: DomainInfo | undefined = data?.domain ? { ...data?.domain } : undefined;
  let colorMap;
  let displayNameMap;
  if (domainInfo) {
    domainInfo.isAdobeMarketingConnected = !!domainInfo?.adobeMarketingConnection?.id;
    domainInfo.isGAConnected = !!domainInfo?.googleOauthConnectionGa?.id;
    domainInfo.isGscConnected = !!domainInfo?.googleOauthConnectionGsc?.id;
    domainInfo.hasAnalytics = domainInfo.isGAConnected || domainInfo.isGscConnected;

    displayNameMap = { [domainInfo.domain]: getDomainName(domainInfo) };
    colorMap = getDomainColors(domainInfo, colorScheme.orange);

    if (domainInfo?.competitors) {
      const colors = colorScheme.graphs.getColors();
      domainInfo.competitors?.map((competitor, i) => {
        colorMap = { ...colorMap, ...getDomainColors(competitor, colors[i % colors.length]) };
        if (competitor?.domain) {
          displayNameMap[competitor.domain] = getDomainName(competitor);
        }
      });
    }
  }

  const asPercentage = data?.domain?.shareOfVoicePercentage;

  const isDemoDomain = domainInfo?.isDemoDomain;
  const isNaverDomain = domainInfo?.domainType === DomainTypeChoices.A_7;

  return {
    domainInfo,
    loadingDomainInfo: loading,
    domainId,
    asPercentage,
    refetch: safeRefetch,
    displayNameMap,
    colorMap,
    isDemoDomain,
    isNaverDomain,
  };
};

export const withDomainInfo = <T extends {} = any>(
  Component: ComponentType<PropsWithChildren<T & UseQueryDomainInfo>>,
) => {
  const result = (props: T) => {
    const { domainInfo, loadingDomainInfo, domainId, refetch } = useQueryDomainInfo();

    return (
      <Component
        domainInfo={domainInfo}
        loadingDomainInfo={loadingDomainInfo}
        domainId={domainId}
        refetch={refetch}
        {...props}
      />
    );
  };
  result.displayName = 'withDomainInfo';

  return result;
};
export const useLanguage = () => useSelector(selectLanguage);
