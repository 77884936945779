import { useMemo, useState } from 'react';
import { Flex, Space } from '@mantine/core';
import AccAlert from 'Components/AccAlert/AccAlert';
import AccSegmentedControl from 'Components/AccSegmentedControl/AccSegmentedControl';
import { LineChart } from 'Components/Chart/LineChart';
import ModalBorder from 'Components/Modal/Layout/ModalBorder';
import AccText from 'Components/Text/AccText';
import AccTitle from 'Components/Title/AccTitle';
import { FolderMetric, useFolderPathHistoryQuery } from 'Ghql';
import { useFilters } from 'Hooks';
import { useModal } from 'Hooks/base/useModal';
import { FolderType } from 'Pages/Keywords/Groupings/groupingTypes';
import { t, tct } from 'Utilities/i18n';
import { getMetricControlOptions } from './helpers/getMetricControlOptions';
import { useGroupingsChartData } from './helpers/useGroupingsChartData';
import styles from './folders-graph.module.scss';

type Props = {
  nodes: FolderType[];
  defaultTab?: FolderMetric;
};

const FoldersGraph = (props: Props) => {
  const { nodes } = props;

  const filters = useFilters();
  const { hideModal } = useModal();
  const folderIds = nodes.map((node) => node.path);
  const [compareBy, setCompareBy] = useState<string>(
    props.defaultTab ?? FolderMetric.EstimatedVisits,
  );

  const { data, loading } = useFolderPathHistoryQuery({
    variables: { filters, paths: folderIds, metric: compareBy as FolderMetric },
  });

  const { series } = useGroupingsChartData(data);
  const isRank = compareBy === FolderMetric.AvgRank || compareBy === FolderMetric.AvgBaseRank;

  const seriesAreEmpty = !series?.length && !loading;

  const controlOptions = useMemo(getMetricControlOptions, []);

  return (
    <ModalBorder
      title={t('Tag and Folder Comparison')}
      onClose={hideModal}
      className={styles.modal}
    >
      <div>
        <Flex mb={18} gap={14} align="center">
          <AccTitle type="h4" mr={14}>
            {t('Compare selected by')}
          </AccTitle>
          <AccSegmentedControl data={controlOptions} value={compareBy} onChange={setCompareBy} />
        </Flex>
        {seriesAreEmpty ? (
          <>
            <Space h={110} />
            <AccAlert mx="auto" w={600} severity="info" singleLine title={t('No data to compare')}>
              <AccText size="sm" fw={400}>
                {tct(
                  'The selected items contains no comparable data. [CloseTheModal] and select a different set of folders or tags to compare.',

                  {
                    CloseTheModal: (
                      <AccText variant="link" inline onClick={hideModal} fw={600} size="sm">
                        {t('Close the modal')}
                      </AccText>
                    ),
                  },
                )}
              </AccText>
            </AccAlert>
            <Space h={110} />
          </>
        ) : (
          <LineChart isLoading={loading} series={series} isRank={isRank} multiLineNavigator />
        )}
      </div>
    </ModalBorder>
  );
};

export const useFoldersGraph = (getNodes: () => FolderType[], defaultTab?: string) => {
  const { showModal } = useModal();
  return () =>
    showModal({
      modalType: 'FoldersGraph',
      modalTheme: 'light',
      modalProps: {
        nodes: getNodes(),
        defaultTab,
      },
    });
};

export default FoldersGraph;
