/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloError } from '@apollo/client';
import { useOrganizationTreeViewQuery } from 'Ghql';
import { useSpecificFilter } from 'Hooks';
import { useUser } from 'Hooks/data/user/useUser';
import { useSmartQuery } from 'Utilities/Graphql/hooks/useSmartQuery';
import { TreeItem } from '../useComparisonContext';
import { processTreeStructure } from './processTreeStructure';

export const useFetchStructure = (): {
  data: TreeItem[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const user = useUser();
  const domainsFilter = useSpecificFilter('domains');
  const clientsFilter = useSpecificFilter('clients');
  const clients = clientsFilter?.value.map((client) => Number(client));
  const domains = domainsFilter?.value.map((domain) => Number(domain));

  const { data, loading, error } = useSmartQuery(useOrganizationTreeViewQuery, {
    variables: {
      organizationId: user.organization!.id,
      clients,
      domains,
    },
    skip: !user.organization?.id,
  });
  const processedData = processTreeStructure(data?.organization?.treeStructure || []);
  return {
    data: processedData,
    loading,
    error,
  };
};
